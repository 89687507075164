import type { UseQueryResult } from '@tanstack/react-query'
import type { SetOptional } from 'type-fest'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get } from '../api'
import boardColumnTypes from '../../shared/constants/boardColumnType'
import { filterBoardColumns } from '../utils/boards'

const useBoardColumns = (
    entityType: Enum.BoardEntityType,
    level: Enum.BoardLevels
): UseQueryResult<DTO.BoardColumn[]> => {
    const { data: session } = useSession()
    return useQuery(['board-columns', entityType, level], async () => {
        let { data } = await get<SetOptional<DTO.BoardColumn, 'module'>[]>(`/boards/columns/${entityType}`, { level })
        // Check if the response is cached by determining if there is a "module" property on the columns
        // Before this was introduced, the original cache time on the API call was set to 1M seconds
        // Even though that cached time has since been changed, some users may have the old data cached
        // TODO: remove this check after a reasonable amount of time (implemented 12/31/24)
        if (data.length && !data[0].module) {
            // If the API data is cached, use the seed data instead
            data = boardColumnTypes
                .filter((t) => t.entityType === entityType && (!t.level || t.level === level))
                .map((t) => ({
                    ...t,
                    type: t.columnType,
                    content_permission: t.contentPermission,
                    entity_type: t.entityType,
                }))
        }

        return filterBoardColumns(data as DTO.BoardColumn[], session)
    })
}

export default useBoardColumns
