import type { Options, SeriesLineOptions } from 'highcharts'
import type { Except } from 'type-fest'
import type { ProfileSectionElementProps } from './ProfileSection'
import type TableConfig from '../../lib/types/tableConfigTypes'
import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { purple, red, lightBlue } from '@mui/material/colors'
import { ascending, flatGroup } from 'd3'
import { formatRegularSeasonLabel } from '../../lib/utils/salary'
import Chart from '../Chart'
import Table from '../Table'
import { useBreakPoints } from '../../lib/hooks'
import { formatNumber, isNumber } from '../../lib/utils/math'

export const getMatchingLOCFromValue = (
    value: number,
    locValues: DTO.LOCValue[] | undefined
): DTO.LOCValue | undefined => {
    if (!locValues?.length) return undefined
    const locValuesCopy = [...locValues].reverse()
    return locValuesCopy.find((l) => Math.round(value) >= l.value)
}
const getLOCDescription = (value: number, locValues: DTO.LOCValue[] | undefined, abbr: boolean): string => {
    const locVal = getMatchingLOCFromValue(value, locValues)
    return (abbr ? locVal?.abbr : locVal?.label) || ''
}

export const formatLOCValue = (value: number, locValues: DTO.LOCValue[] | undefined, missingString = '-'): string => {
    const loc = getMatchingLOCFromValue(value, locValues)
    if (!loc) return missingString
    const locDiff = value - loc.value
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `${loc.abbr!} (${locDiff >= 0 ? '+' : ''}${formatNumber(locDiff) || ''})`
}

const blendAvg = (sLOC: number | null | undefined, iLOC: number | null | undefined) =>
    isNumber(sLOC) && isNumber(iLOC) ? (sLOC + iLOC) / 2 : null

type LOCProjectionsProps = ProfileSectionElementProps & {
    season: number
    locs: DTO.LOCProjections[] | undefined
    isProjectionsLoading: boolean
    scoutingReport: DTO.ScoutingReport | undefined
    isScoutingReportLoading: boolean
    locValues: DTO.LOCValue[] | undefined
    isLOCValuesLoading: boolean
    level: Enum.PlayerLevel | null | undefined
}

export type LOCRow = {
    title: Enum.LOCProjectionType
} & {
    [loc in DTO.LOCTypes]: number | null | undefined
}
export const locRowOptions: Record<Enum.LOCProjectionType, Except<SeriesLineOptions, 'type'>> = {
    Scouts: {
        color: red[300],
        marker: {
            symbol: 'circle',
        },
    },
    Insights: {
        color: lightBlue[500],
        marker: {
            symbol: 'triangle',
        },
    },
    Blend: {
        color: purple[500],
        lineWidth: 3,
        marker: {
            symbol: 'square',
        },
    },
} as const
const RowLegend = ({ title }: { title: Enum.LOCProjectionType }): JSX.Element => {
    const { color, marker } = locRowOptions[title]
    let symbol: 'M 5,1 9,9 1,9 z' | 'M 2,3 8,3 8,9 2,9 z' | 'M 2, 5 a 3,3 0 1,0 6,0 a 3,3 0 1,0 -6,0 z' =
        'M 2, 5 a 3,3 0 1,0 6,0 a 3,3 0 1,0 -6,0 z'
    if (marker?.symbol === 'square') {
        symbol = 'M 2,3 8,3 8,9 2,9 z'
    } else if (marker?.symbol === 'triangle') {
        symbol = 'M 5,1 9,9 1,9 z'
    }
    return (
        <>
            <Box
                component="span"
                sx={{ color: color as string, display: 'inline-block', textAlign: 'center', width: 14, mr: 0.5 }}
            >
                <svg fill={color as string} width="10" height="10">
                    <path d={symbol} />
                </svg>
            </Box>
            {title}
        </>
    )
}

export const formatLOC = (l: number | null | undefined, locValues: DTO.LOCValue[] | undefined): JSX.Element => {
    const val = isNumber(l) && getMatchingLOCFromValue(l, locValues)
    if (!val || !locValues) return <> </>
    const locDiff = l - val.value
    return (
        <>
            {val.abbr}{' '}
            <Typography sx={{ color: 'text.secondary', fontSize: 'inherit', lineHeight: 'inherit' }} component="span">
                {locDiff >= 0 ? '+' : ''}
                {formatNumber(locDiff) || ''}
            </Typography>
        </>
    )
}

export const mapLocRows = (
    locs: DTO.LOCProjections[] | undefined,
    scoutingReport: DTO.ScoutingReport | undefined,
    level: Enum.PlayerLevel | null | undefined,
    season: number
): LOCRow[] => {
    let scoutingLOCs: Except<LOCRow, 'title'> | undefined
    let insightLOCs: Except<LOCRow, 'title'> | undefined
    let blendLOCs: Except<LOCRow, 'title'> | undefined
    if (scoutingReport) {
        scoutingLOCs = {
            now: scoutingReport.locNowValue,
            low: scoutingReport.locLowValue,
            bullseye: scoutingReport.locBullseyeValue,
            high: scoutingReport.locHighValue,
        }
    }

    const insights = locs?.filter((l) => l.type === 'Insights')
    if (insights?.length) {
        // Allow future season LOCs if they're not predictions
        const currentSeasonLOCs = insights.reduce(
            (acc, obj) =>
                (obj.season >= season && obj.prediction === false && (acc === undefined || obj.season > acc.season)) ||
                (obj.season === season && acc === undefined)
                    ? obj
                    : acc,
            undefined as DTO.LOCProjections | undefined
        )
        const bullseyeSeasonLOC = insights.find((l) => l.season === season + 4)?.loc
        if (currentSeasonLOCs?.prediction === false) {
            insightLOCs = {
                now: currentSeasonLOCs.loc,
                bullseye: currentSeasonLOCs.locBullseye || bullseyeSeasonLOC,
                low: currentSeasonLOCs.locLow,
                high: currentSeasonLOCs.locHigh,
            }
        } else {
            insightLOCs = { now: currentSeasonLOCs?.loc, bullseye: bullseyeSeasonLOC, low: null, high: null }
        }
    }
    if (scoutingLOCs && insightLOCs) {
        blendLOCs = {
            now: blendAvg(scoutingLOCs.now, insightLOCs.now),
            low: blendAvg(scoutingLOCs.low, insightLOCs.low),
            bullseye: blendAvg(scoutingLOCs.bullseye, insightLOCs.bullseye),
            high: blendAvg(scoutingLOCs.high, insightLOCs.high),
        }
    }

    const locRows: LOCRow[] = []
    if (scoutingLOCs) locRows.push({ title: 'Scouts', ...scoutingLOCs })
    if (insightLOCs && level === 'PRO') locRows.push({ title: 'Insights', ...insightLOCs })
    if (blendLOCs && level === 'PRO') locRows.push({ title: 'Blend', ...blendLOCs })

    return locRows
}

const LOCProjections = ({
    season,
    locs,
    level,
    isProjectionsLoading,
    scoutingReport,
    isScoutingReportLoading,
    locValues,
    isLOCValuesLoading,
    printCardId,
}: LOCProjectionsProps): JSX.Element => {
    const { isMobile } = useBreakPoints()
    const chartOptions = useMemo<Options>(() => {
        // Only show future seasons for amateur players
        const minSeason = level === 'AM' ? season + 1 : season - 2
        const maxSeason = level === 'AM' ? season + 6 : season + 4
        const filteredLOCs =
            locs?.filter(
                (l) => l.season >= minSeason && l.season <= maxSeason && (l.type === 'Scouts' || level === 'PRO')
            ) || []
        const series = flatGroup(filteredLOCs, (l) => l.type).map<SeriesLineOptions>(([name, data], _, self) => ({
            name,
            type: 'line',
            animation: false,
            data: data
                .sort((a, b) => ascending(a.season, b.season))
                .map((l) => ({
                    x: l.season,
                    y: l.loc,
                    name: isMobile
                        ? `${formatRegularSeasonLabel(l.season)} : ${formatNumber(l.age, 0, 'decimal') || ''}`
                        : `${formatRegularSeasonLabel(l.season)}<br/>${formatNumber(l.age, 0, 'decimal') || ''}`,
                    marker: {
                        fillColor: l.prediction ? 'white' : locRowOptions[name].color,
                        lineColor: locRowOptions[name].color,
                        lineWidth: 1,
                    },
                })),
            opacity: name !== 'Blend' && self.length > 1 ? 0.75 : 1,
            ...locRowOptions[name],
        }))
        return {
            title: { text: undefined },
            series,
            xAxis: {
                type: 'category',
            },
            yAxis: {
                max: 8,
                min: 1,
                minTickInterval: 1,
                tickAmount: locValues?.length,
                title: {
                    text: undefined,
                },
                labels: {
                    formatter: function yAxisFormatter() {
                        const { value } = this
                        return getLOCDescription(Number(value), locValues, isMobile)
                    },
                },
            },
            tooltip: {
                pointFormatter: function tooltipPointFormatter() {
                    const {
                        y,
                        series: { name },
                    } = this
                    return `${name}: <b>${getLOCDescription(Number(y), locValues, isMobile)}</b><br/>`
                },
            },
            legend: {
                enabled: false,
            },
        }
    }, [isMobile, locValues, locs, season, level])

    const tableConfig = useMemo<TableConfig<LOCRow>>(
        () => ({
            loadingSkeleton: {
                numOfRows: 2,
                height: 100,
            },
            fields: [
                { key: 'title', label: '', select: RowLegend, skeletonStyle: 75 },
                { key: 'now', label: 'Now', select: (s) => formatLOC(s.now, locValues), skeletonStyle: 75 },
                { key: 'low', label: 'Low', select: (s) => formatLOC(s.low, locValues), skeletonStyle: 75 },
                { key: 'bullseye', label: 'Bull', select: (s) => formatLOC(s.bullseye, locValues), skeletonStyle: 75 },
                { key: 'high', label: 'High', select: (s) => formatLOC(s.high, locValues), skeletonStyle: 75 },
            ],
        }),
        [locValues]
    )

    const rows = useMemo(() => mapLocRows(locs, scoutingReport, level, season), [scoutingReport, locs, level, season])

    return (
        <div id={isScoutingReportLoading || isProjectionsLoading || isLOCValuesLoading ? undefined : printCardId}>
            <Table<LOCRow>
                isLoading={isScoutingReportLoading || isLOCValuesLoading}
                config={tableConfig}
                rows={rows}
                getRowKey={(r) => r.title}
                emptyValue="-"
            />
            <Chart isLoading={isProjectionsLoading || isLOCValuesLoading} options={chartOptions} />
        </div>
    )
}

export default LOCProjections
