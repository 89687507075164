import { useMemo } from 'react'
import { safeRatio } from '../utils/math'

export type PlayerSeasonBoxscoreStats = {
    ppg: number | null
    mpg: number | null
    '2fg%': number | null
    '3fg%': number | null
    'ft%': number | null
    orbpg?: number | null
    drbpg?: number | null
    rbpg: number | null
    astpg: number | null
    tovpg: number | null
    stlpg: number | null
    blkpg: number | null
    gp: number
    gs: number
    season?: number | null
    team?: string | null
    pfpg?: number | null
    type?: Enum.GameType
    league?: Enum.League
}

const usePlayerSeasonBoxscoreStats = (
    seasonStats: DTO.SeasonStats[] | undefined,
    isLoading: boolean,
    filterLogic: (s: DTO.SeasonStats) => boolean
): PlayerSeasonBoxscoreStats[] =>
    useMemo(() => {
        if (!seasonStats?.length || isLoading) return []
        return seasonStats
            .filter((s) => filterLogic(s))
            .map((s) => ({
                ppg: safeRatio(s.pts, s.gp),
                mpg: safeRatio(s.min, s.gp),
                '2fg%': safeRatio(s['2pm'], s['2pa']),
                '3fg%': safeRatio(s['3pm'], s['3pa']),
                'ft%': safeRatio(s.ftm, s.fta),
                orbpg: safeRatio(s.orb, s.gp),
                drbpg: safeRatio(s.drb, s.gp),
                rbpg: safeRatio(s.drb + s.orb, s.gp),
                astpg: safeRatio(s.ast, s.gp),
                tovpg: safeRatio(s.to, s.gp),
                stlpg: safeRatio(s.stl, s.gp),
                blkpg: safeRatio(s.blk, s.gp),
                pfpg: safeRatio(s.fouls, s.gp),
                gs: s.gs,
                gp: s.gp,
                league: s.league,
                team: s.teamAbbr,
                season: s.season,
                type: s.type,
            }))
    }, [seasonStats, isLoading, filterLogic])

export default usePlayerSeasonBoxscoreStats
