import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

export type SecondaryPositionSelectProps = {
    secondaryPosition: string
    setSecondaryPosition: (position: DTO.PlayerSecondaryPosition | undefined) => void
    showLabel?: boolean
}

const SecondaryPositionSelect = ({
    secondaryPosition,
    setSecondaryPosition,
    showLabel = false,
}: SecondaryPositionSelectProps): JSX.Element => (
    <FormControl fullWidth>
        {showLabel && <InputLabel id="secondaryPosition">Secondary Pos.</InputLabel>}
        <Select
            labelId="secondaryPosition"
            id="secondaryPosition"
            value={secondaryPosition}
            defaultValue={secondaryPosition}
            onChange={(e) => setSecondaryPosition(e.target.value as DTO.PlayerSecondaryPosition)}
            size="small"
            sx={
                showLabel
                    ? {
                          textAlign: 'left',
                          height: '30px',
                          backgroundColor: 'common.white',
                          minWidth: '105px',
                      }
                    : { minWidth: 100, fontSize: '0.75rem', fontWeight: '500' }
            }
            label={showLabel ? 'Secondary Pos.' : undefined}
        >
            <MenuItem key="bigs" value="Bigs">
                Bigs
            </MenuItem>
            <MenuItem key="wings" value="Wings">
                Wings
            </MenuItem>
            <MenuItem key="guard" value="Guard">
                Guard
            </MenuItem>
        </Select>
    </FormControl>
)

export default SecondaryPositionSelect
