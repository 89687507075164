import React from 'react'
import Box from '@mui/material/Box'
import PlayerHeadshotAvatar from '../boards/PositionalBoard/PlayerHeadshotAvatar'
import { formatHeight } from '@/lib/utils/formatters'

type PostHeadshotProps = {
    tag: DTO.PlayerTag | null | undefined
    marginRight?: number
}

const HeadshotToolTip = ({
    playerName,
    playerWeight,
    playerHeight,
    playerPosition,
    teamName,
}: {
    playerName: string | null
    playerWeight: number | null | undefined
    playerHeight: number | null | undefined
    playerPosition: string | null | undefined
    teamName: string | null
}): JSX.Element => {
    const info = [
        { key: 'pos', val: playerPosition },
        { key: 'height', val: playerHeight, format: (val: number) => formatHeight(val, 0) },
        { key: 'weight', val: playerWeight, format: (val: number) => `${val} lbs` },
    ]
    return (
        <Box>
            <Box>
                {playerName || ''}
                {teamName ? ` - ${teamName}` : ''}
            </Box>
            <Box sx={{ display: 'flex' }}>
                {info
                    .filter((d) => d.val)
                    .map((d) => (
                        <Box key={d.key} sx={{ marginRight: 1 }}>
                            {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                d.format ? d.format(d.val!) : d.val
                            }
                        </Box>
                    ))}
            </Box>
        </Box>
    )
}

export const PostHeadshot = ({ tag, marginRight = 1 }: PostHeadshotProps): JSX.Element => {
    if (!tag) return <Box />
    return (
        <Box sx={{ float: 'left', display: 'inline-block', marginRight }}>
            <PlayerHeadshotAvatar
                outlined
                imageUrl={tag.playerHeadshot}
                teamImageUrl={tag.teamLogoUrl}
                height={35}
                width={35}
                toolTip={
                    <HeadshotToolTip
                        playerName={tag.name}
                        teamName={tag.teamName}
                        playerPosition={tag.playerPosition}
                        playerHeight={tag.playerHeight}
                        playerWeight={tag.playerWeight}
                    />
                }
            />
        </Box>
    )
}
