import type { Session } from 'next-auth'

export const filterBoardColumns = (
    boardColumns: DTO.BoardColumn[] | undefined,
    session: Session | null
): DTO.BoardColumn[] => {
    if (!boardColumns || !session) return []

    return boardColumns.filter((d) => {
        if (d.type === 'intelReports' || d.type === 'intelReportsAll')
            return (
                session.roles.contentPermissions['pro-intel-on-court'] ||
                session.roles.contentPermissions['pro-intel-off-court'] ||
                session.roles.contentPermissions['pro-intel-medical-physical'] ||
                session.roles.contentPermissions['pro-intel-strategy'] ||
                session.roles.contentPermissions['amateur-intel-on-court'] ||
                session.roles.contentPermissions['amateur-intel-off-court'] ||
                session.roles.contentPermissions['amateur-intel-medical-physical'] ||
                session.roles.contentPermissions['amateur-intel-strategy']
            )
        if (d.type === 'scoutReports' || d.type === 'scoutReportsAll')
            return (
                session.roles.contentPermissions['pro-scouting-reports'] ||
                session.roles.contentPermissions['amateur-scouting-reports']
            )

        return !d.content_permission || session.roles.contentPermissions[d.content_permission]
    })
}
