import type TableConfig from '../../lib/types/tableConfigTypes'
import type { PlayerSeasonBoxscoreStats } from '@/lib/hooks/usePlayerSeasonBoxscoreStats'
import React from 'react'
import Box from '@mui/material/Box'
import Table from '../Table'
import { formatSeasonType } from './PlayerSeasonBoxScore'
import { horizontalStickyStyles } from '@/lib/utils/sticky'
import { formatNumber, isNumber } from '@/lib/utils/math'
import { formatSeason } from '@/lib/utils/formatters'

export type PlayerGameSeasonComboBoxscoreStats = Pick<
    DTO.GameBoxscore,
    'gameName' | 'plusMinus' | 'gameId' | 'playerId'
> & {
    gp: number
    gs: number
    min: null | string
    fgm: string | null
    '3pm': string | null
    tft: string | null
    pts: string | number | null
    trb: string | number | null
    orb: string | number | null
    drb: string | number | null
    ast: string | number | null
    stl: string | number | null
    blk: string | number | null
    to: string | number | null
    fouls: string | number | null
}
type PlayerGameSeasonComboBoxscoreProps = {
    boxscores: DTO.GameBoxscore[]
    seasonStats: PlayerSeasonBoxscoreStats[]
    isBoxScoreLoading?: boolean
}

export const boxscoreFields: TableConfig<PlayerGameSeasonComboBoxscoreStats>['fields'] = [
    {
        key: 'gameName',
        label: 'GAME',
        select: (x) => x.gameName,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary', ...horizontalStickyStyles },
        format: {
            whiteSpace: 'nowrap',
            paddingX: '10px',
            width: 175,
            minWidth: 175,
            maxWidth: 175,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            ...horizontalStickyStyles,
        },
        skeletonStyle: { xs: 100 },
    },
    {
        key: 'gp',
        label: 'GP',
        select: (x) => x.gp,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'gs',
        label: 'GS',
        select: (x) => x.gs,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'min',
        label: 'MIN',
        select: (x) => (
            <Box component="span" className="boxscore-min" sx={{ backgroundColor: 'white', position: 'relative' }}>
                {x.min}
            </Box>
        ),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: {
            overflow: 'visible',
            whiteSpace: 'nowrap',
            paddingX: '10px',
            width: 50,
            maxWidth: 50,
            minWidth: 50,
        },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'pts',
        label: 'PTS',
        select: (x) => x.pts,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'fgm',
        label: 'FG',
        select: (x) => x.fgm,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: '3pm',
        label: '3P',
        select: (x) => x['3pm'],
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'tft',
        label: 'FT',
        select: (x) => x.tft,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'trb',
        label: 'REB',
        select: (x) => x.trb,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'orb',
        label: 'OREB',
        select: (x) => x.orb,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'drb',
        label: 'DREB',
        select: (x) => x.drb,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'ast',
        label: 'AST',
        select: (x) => x.ast,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'stl',
        label: 'STL',
        select: (x) => x.stl,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'blk',
        label: 'BLK',
        select: (x) => x.blk,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'to',
        label: 'TO',
        select: (x) => x.to,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'fouls',
        label: 'PF',
        select: (x) => x.fouls,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'plusMinus',
        label: '+/-',
        select: (x) => x.plusMinus,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
]

const tableConfig = {
    loadingSkeleton: {
        numOfRows: 3,
        height: 90,
    },
    fields: boxscoreFields,
}

const PlayerGameSeasonComboBoxscore = ({
    boxscores,
    seasonStats,
    isBoxScoreLoading,
}: PlayerGameSeasonComboBoxscoreProps): JSX.Element => {
    const playerSeasonStatsBoxscore: PlayerGameSeasonComboBoxscoreStats[] = seasonStats.map((s) => ({
        gameName: formatSeasonType(s),
        gameId: s.season ? formatSeason(s.season) : '',
        playerId: '',
        gp: s.gp,
        gs: s.gs,
        min: formatNumber(s.mpg),
        pts: formatNumber(s.ppg),
        fgm: s['2fg%'] ? formatNumber(s['2fg%'] * 100) : null,
        '3pm': s['3fg%'] ? formatNumber(s['3fg%'] * 100) : null,
        tft: s['ft%'] ? formatNumber(s['ft%'] * 100) : null,
        trb: formatNumber(s.rbpg),
        orb: formatNumber(s.orbpg),
        drb: formatNumber(s.drbpg),
        ast: formatNumber(s.astpg),
        stl: formatNumber(s.stlpg),
        blk: formatNumber(s.blkpg),
        to: formatNumber(s.tovpg),
        fouls: formatNumber(s.pfpg),
        plusMinus: null,
    }))

    const playerGameBoxscore: PlayerGameSeasonComboBoxscoreStats[] | undefined = boxscores.map((b) => ({
        gameName: b.gameName,
        gameId: b.gameId,
        playerId: b.playerId,
        gp: b.gp ? 1 : 0,
        gs: b.gs ? 1 : 0,
        min: b.min && b.gp ? formatNumber(b.min, 0) : b.dnpReason || 'DNP',
        pts: isNumber(b.pts) ? b.pts : null,
        fgm: isNumber(b.fgm) && isNumber(b.fga) ? `${b.fgm}/${b.fga}` : null,
        '3pm': isNumber(b['3pm']) && isNumber(b['3pa']) ? `${b['3pm']}/${b['3pa']}` : null,
        tft: isNumber(b.ftm) && isNumber(b.fta) ? `${b.ftm}/${b.fta}` : null,
        trb: isNumber(b.orb) || isNumber(b.drb) ? (b.orb || 0) + (b.drb || 0) : null,
        orb: b.orb,
        drb: b.drb,
        ast: b.ast,
        stl: b.stl,
        blk: b.blk,
        to: b.to,
        fouls: b.fouls,
        plusMinus: b.plusMinus,
    }))

    return (
        <Table<PlayerGameSeasonComboBoxscoreStats>
            rows={[...playerGameBoxscore, ...playerSeasonStatsBoxscore]}
            config={tableConfig}
            isLoading={isBoxScoreLoading}
            hideSorting
            getRowKey={(r) => `${r.gameName}-${r.playerId}`}
            emptyValue="-"
            hover={false}
        />
    )
}

export default PlayerGameSeasonComboBoxscore
