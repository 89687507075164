import type { LegendColorProps } from '../../components/LegendColor'
import useBreakPoints from './useBreakpoints'

export const salaryColors = (isMobile: boolean): LegendColorProps[] => [
    { label: isMobile ? 'ETO' : 'Early Term Opt', color: '#8ea8db' },
    { label: isMobile ? 'TO' : 'Team Opt', color: '#70ad47', fontColor: 'white' },
    { label: isMobile ? 'PO' : 'Player Opt', color: '#106bb6', fontColor: 'white' },
    { label: 'PG or NG', color: '#fdc002' },
    { label: isMobile ? 'Ext.' : 'Future Ext.', color: '#ffffff', fontColor: 'black', fontStyle: 'italic' },
    {
        label: isMobile ? 'Opt.' : 'Declined Opt.',
        color: '#ffffff',
        fontColor: 'black',
        textDecoration: 'line-through',
    },
]

export const useLegendColors = (): [LegendColorProps[], LegendColorProps[]] => {
    const { isMobile } = useBreakPoints()
    const colors: LegendColorProps[] = [
        { label: isMobile ? '10%' : 'Bot 10%', color: 'legend.bot10' },
        { label: '20', color: 'legend.bot20' },
        { label: '30', color: 'legend.bot30' },
        { label: '40', color: 'legend.bot40' },
        { label: '60', color: 'legend.top60' },
        { label: '70', color: 'legend.top70' },
        { label: '80', color: 'legend.top80' },
        { label: isMobile ? '90%' : 'Top 90%', color: 'legend.top90' },
    ]

    return [colors, salaryColors(isMobile)]
}
