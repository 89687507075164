'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'

type UseShootingParamsReturn = {
    defaultParams: DTO.ShotFilters
    filterParams: DTO.ShotFilters
    setFilterParams: Dispatch<SetStateAction<DTO.ShotFilters>>
    isPrintOnly: boolean
    season: number
    defaultYears: number[]
}

export type ShotTypeOption = {
    label: string
    value: Enum.ShotType
}
export const shotTypes: ShotTypeOption[] = [
    {
        label: 'Dunk',
        value: 'DUNK',
    },
    {
        label: 'Layup',
        value: 'LAYUP',
    },
    {
        label: 'Floater',
        value: 'FLOATER',
    },
    {
        label: 'Post',
        value: 'POST',
    },
    {
        label: 'Jumper',
        value: 'JUMPER',
    },
    {
        label: 'Heave',
        value: 'HEAVE',
    },
]

export type GameStateOption = {
    label: string
    value: Enum.GameState
}

export const gameStates: GameStateOption[] = [
    {
        label: 'Half Court',
        value: 'HALF_COURT',
    },
    {
        label: 'Transition',
        value: 'TRANSITION',
    },
    { label: 'Putback', value: 'PUTBACK' },
]

export type ShotMetricType = 'category' | 'range'
export type ShotMetric = {
    label: string
    value: Enum.ShotMetric
    type: ShotMetricType
    league: Record<Enum.League, boolean>
    fields: { label: string; value: string | number }[]
}

export const shotMetrics: ShotMetric[] = [
    {
        label: 'Around Rim Finish',
        value: 'aroundTheRimFinish',
        type: 'category',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Dunk', value: 'DUNK' },
            { label: 'Two Handed', value: 'TWO_HANDED' },
            { label: 'Shot', value: 'SHOT' },
            { label: 'Underhand Scoop', value: 'UNDERHAND_SCOOP' },
            { label: 'Underhand Layup', value: 'UNDERHAND_LAYUP' },
            { label: 'Overhand Push', value: 'OVERHAND_PUSH' },
            { label: 'Tip In', value: 'TIP_IN' },
            { label: 'Hook', value: 'HOOK' },
        ],
    },
    {
        label: 'Contest Level',
        value: 'contestLevel',
        type: 'category',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Uncontested', value: 'uncontested' },
            { label: 'Lightly Contested', value: 'lightly_contested' },
            { label: 'Heavily Contested', value: 'heavily_contested' },
        ],
    },
    {
        label: 'Dribbles Taken',
        type: 'range',
        value: 'dribblesTaken',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Min', value: 0 },
            { label: 'Max', value: 30 },
        ],
    },
    {
        label: 'Game State',
        type: 'category',
        value: 'gameStates',
        league: {
            College: true,
            International: true,
            NBA: true,
            'G-League': true,
            Summer: true,
            FIBA: true,
            OTE: true,
            Prep: true,
        },
        fields: gameStates,
    },
    {
        label: 'Play Type',
        type: 'category',
        value: 'previousEvent',
        league: {
            College: true,
            International: true,
            NBA: true,
            'G-League': true,
            Summer: true,
            FIBA: true,
            OTE: true,
            Prep: true,
        },
        fields: [
            { label: 'Handoff', value: 'HANDOFF' },
            { label: 'Isolation', value: 'ISOLATION' },
            { label: 'Pick', value: 'PICK' },
            { label: 'Post', value: 'POST' },
        ],
    },
    {
        label: 'Release Hand',
        type: 'category',
        value: 'releaseHand',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Left Hand', value: 'LEFT_HAND' },
            { label: 'Right Hand', value: 'RIGHT_HAND' },
            { label: 'Two Hands', value: 'TWO_HANDS' },
        ],
    },
    {
        label: 'Shot Clock',
        type: 'range',
        value: 'shotClock',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Shot Clock', value: 0 },
            { label: 'Shot Clock', value: 24 },
        ],
    },

    {
        label: 'Shot Jump Direction',
        type: 'category',
        value: 'shotJumpCategory',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Left', value: 'LEFT' },
            { label: 'Right', value: 'RIGHT' },
            { label: 'Towards Rim', value: 'TOWARDS_RIM' },
            { label: 'Straight Up', value: 'STRAIGHT_UP' },
            { label: 'Fadeaway', value: 'FADEAWAY' },
        ],
    },
    {
        label: 'Shot Jump Footing Takeoff',
        type: 'category',
        value: 'shotJumpFootingTakeoff',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Left Foot', value: 'LEFT_FOOT' },
            { label: 'Right Foot', value: 'RIGHT_FOOT' },
            { label: 'Both Feet', value: 'BOTH_FEET' },
        ],
    },
    {
        label: 'Shot Jump Footing Landing',
        type: 'category',
        value: 'shotJumpFootingLanding',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Left Foot', value: 'LEFT_FOOT' },
            { label: 'Right Foot', value: 'RIGHT_ROOT' },
            { label: 'Both Feet', value: 'BOTH_FEET' },
        ],
    },
    {
        label: 'Shot Outcome',
        type: 'category',
        value: 'complexOutcome',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Swish', value: 'SWISH' },
            { label: 'Airball', value: 'AIRBALL' },
            { label: 'Backboard', value: 'BACKBOARD' },
            { label: 'Normal Make', value: 'NORMAL_MAKE' },
            { label: 'Rim Bounce Make', value: 'RIM_BOUNCE_MAKE' },
            { label: 'Outside Rim Miss', value: 'OUTSIDE_RIM_MISS' },
            { label: 'Inside Rim Miss', value: 'INSIDE_RIM_MISS' },
        ],
    },
    {
        label: 'Shot Type',
        type: 'category',
        value: 'shotTypes',
        league: {
            College: true,
            International: true,
            NBA: true,
            'G-League': true,
            Summer: true,
            FIBA: true,
            OTE: true,
            Prep: true,
        },
        fields: shotTypes,
    },
    {
        label: 'Shot Type Complex',
        type: 'category',
        value: 'shotTypeComplex',
        league: {
            College: false,
            International: false,
            NBA: true,
            'G-League': true,
            Summer: false,
            FIBA: false,
            OTE: false,
            Prep: false,
        },
        fields: [
            { label: 'Catch and Shoot', value: 'CATCH_AND_SHOOT' },
            { label: 'Catch and Shoot on Move Left', value: 'CATCH_AND_SHOOT_ON_MOVE_LEFT' },
            { label: 'Catch and Shoot on Move Right', value: 'CATCH_AND_SHOOT_ON_MOVE_RIGHT' },
            { label: 'Catch and Shoot Relocating', value: 'CATCH_AND_SHOOT_RELOCATING' },
            { label: 'Cut Floater', value: 'CUT_FLOATER' },
            { label: 'Cut Layup', value: 'CUT_LAYUP' },
            { label: 'Driving Floater', value: 'DRIVING_FLOATER' },
            { label: 'Driving Layup', value: 'DRIVING_LAYUP' },
            { label: 'Heave', value: 'HEAVE' },
            { label: 'Lob', value: 'LOB' },
            { label: 'Over Screen', value: 'OVER_SCREEN' },
            { label: 'Post Left', value: 'POST_LEFT' },
            { label: 'Post Right', value: 'POST_RIGHT' },
            { label: 'Pullup Jumper', value: 'PULLUP_JUMPER' },
            { label: 'Shake and Raise', value: 'SHAKE_AND_RAISE' },
            { label: 'Standstill Layup', value: 'STANDSTILL_LAYUP' },
            { label: 'Stepback', value: 'STEPBACK' },
            { label: 'Tip', value: 'TIP' },
        ],
    },
]

export const getInitFilterParams = (
    defaultParams: DTO.ShotFilters,
    queryParams: Partial<DTO.ShotFilters>
): DTO.ShotFilters => ({
    playerId: queryParams.playerId || defaultParams.playerId,
    teamId: queryParams.teamId || defaultParams.teamId,
    league: queryParams.league || defaultParams.league,
    timeFrame: queryParams.timeFrame || defaultParams.timeFrame,
    startDate: queryParams.startDate || defaultParams.startDate,
    endDate: queryParams.endDate || defaultParams.endDate,
    gameIds: (queryParams.gameIds ? JSON.parse(queryParams.gameIds as string) : defaultParams.gameIds) as string[],
    opponentTeamId: queryParams.opponentTeamId || defaultParams.opponentTeamId,
    type: queryParams.type || defaultParams.type,
    shotTypes: queryParams.shotTypes
        ? (JSON.parse(queryParams.shotTypes as string) as Enum.ShotType[])
        : defaultParams.shotTypes,
    seasons: queryParams.seasons ? (JSON.parse(queryParams.seasons as string) as number[]) : defaultParams.seasons,
    level: queryParams.level || defaultParams.level,
    gameStates: queryParams.gameStates
        ? (JSON.parse(queryParams.gameStates as string) as Enum.GameState[])
        : defaultParams.gameStates,
    aroundTheRimFinish: queryParams.aroundTheRimFinish
        ? (JSON.parse(queryParams.aroundTheRimFinish as string) as Enum.AroundRimFinishType[])
        : defaultParams.aroundTheRimFinish,
    contestLevel: queryParams.contestLevel
        ? (JSON.parse(queryParams.contestLevel as string) as Enum.ContestLevel[])
        : defaultParams.contestLevel,
    complexOutcome: queryParams.complexOutcome
        ? (JSON.parse(queryParams.complexOutcome as string) as Enum.ComplexOutcome[])
        : defaultParams.complexOutcome,
    dribblesTakenMin: queryParams.dribblesTakenMin || defaultParams.dribblesTakenMin,
    dribblesTakenMax: queryParams.dribblesTakenMax || defaultParams.dribblesTakenMax,
    previousEvent: queryParams.previousEvent
        ? (JSON.parse(queryParams.previousEvent as string) as Enum.PlayType[])
        : defaultParams.previousEvent,
    releaseHand: queryParams.releaseHand
        ? (JSON.parse(queryParams.releaseHand as string) as Enum.ReleaseHand[])
        : defaultParams.releaseHand,
    shotClockMin: queryParams.shotClockMin || defaultParams.shotClockMin,
    shotClockMax: queryParams.shotClockMax || defaultParams.shotClockMax,
    shotJumpFootingTakeoff: queryParams.shotJumpFootingTakeoff
        ? (JSON.parse(queryParams.shotJumpFootingTakeoff as string) as Enum.ShotJumpFootingTakeoff[])
        : defaultParams.shotJumpFootingTakeoff,
    shotJumpFootingLanding: queryParams.shotJumpFootingLanding
        ? (JSON.parse(queryParams.shotJumpFootingLanding as string) as Enum.ShotJumpFootingLanding[])
        : defaultParams.shotJumpFootingLanding,
    shotTypeComplex: queryParams.shotTypeComplex
        ? (JSON.parse(queryParams.shotTypeComplex as string) as Enum.ComplexShotType[])
        : defaultParams.shotTypeComplex,
})

export default (
    playerId: string | null | undefined,
    teamId: string | null | undefined,
    league: Enum.League | undefined,
    selectedSeason: number,
    maxSeason: number,
    isReadOnly: boolean
): UseShootingParamsReturn => {
    const searchParams = useSearchParams()
    const queryParams = URLSearchParamsToObject(searchParams)
    const startYear = Math.max(selectedSeason, maxSeason)
    const endYear = Math.min(selectedSeason, maxSeason) - 3
    const length = startYear - endYear + 1
    const defaultYears = useMemo(() => Array.from({ length }, (_, i) => startYear - i), [length, startYear])
    const isPrintOnly = !!searchParams?.get('printOnly')
    const seasons = useMemo(
        () => (isReadOnly ? defaultYears : [selectedSeason]),
        [defaultYears, isReadOnly, selectedSeason]
    )

    const defaultParams = useMemo<DTO.ShotFilters>(
        () => ({
            playerId: playerId || undefined,
            teamId: teamId || undefined,
            seasons,
            league: league || 'NBA',
            timeFrame: (league === 'NBA'
                ? ['REGULAR']
                : league === 'G-League'
                ? ['SEASON']
                : ['AM_SEASON']) as DTO.TimeFrame[],
            startDate: undefined,
            endDate: undefined,
            gameIds: [],
            playersToExclude: [],
            opponentTeamId: undefined,
            shotTypes: [],
            type: 'offense',
            level: ['NBA', 'G-League', 'Summer'].includes(league || 'NBA') ? 'NBA' : 'FIBA',
            gameStates: [],
            aroundTheRimFinish: [],
            contestLevel: [],
            complexOutcome: [],
            dribblesTakenMin: undefined,
            dribblesTakenMax: undefined,
            previousEvent: [],
            releaseHand: [],
            shotClockMin: undefined,
            shotClockMax: undefined,
            shotJumpCategory: [],
            shotJumpFootingTakeoff: [],
            shotJumpFootingLanding: [],
            shotTypeComplex: [],
        }),
        [playerId, teamId, league, seasons]
    )

    const [filterParams, setFilterParams] = useState<DTO.ShotFilters>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    return {
        defaultParams,
        filterParams,
        setFilterParams,
        isPrintOnly,
        season: selectedSeason,
        defaultYears,
    }
}
