import type { UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation } from '@tanstack/react-query'
import { post } from '../api'

export const useSetupPrintJob = (): UseMutationResult<JSONResponse, Error, DTO.SetupPrintJobParams> =>
    useMutation((params: DTO.SetupPrintJobParams) => post('/print/setup-profile-cache', params, params))

export const usePrintProfile = (): UseMutationResult<JSONResponse<string>, Error, DTO.PrintParams> =>
    useMutation((params: DTO.PrintParams) =>
        post<string, DTO.PrintParams>('/print/print-profile', { ...params, url: process.env.NEXT_PUBLIC_NEXTAUTH_URL })
    )

export const usePrintTeamProfile = (): UseMutationResult<JSONResponse<string>, Error, DTO.TeamProfilePrintParams> =>
    useMutation((params: DTO.TeamProfilePrintParams) =>
        post<string, DTO.TeamProfilePrintParams>('/print/team-profile', {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

export const usePrintAgent = (): UseMutationResult<JSONResponse<string>, Error, DTO.AgentPrintParams> =>
    useMutation((params: DTO.AgentPrintParams) =>
        post<string, DTO.AgentPrintParams>('/print/agent', {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

export const usePrintDraftDigest = (): UseMutationResult<JSONResponse<string>, Error, DTO.DraftPrintParams> =>
    useMutation((params: DTO.DraftPrintParams) =>
        post<string, DTO.DraftPrintParams>('/print/draft-materials', {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

export const usePrintTeamStats = (): UseMutationResult<JSONResponse<string>, Error, DTO.TeamStatsPrintJob> =>
    useMutation((params: DTO.TeamStatsPrintJob) =>
        post<string, DTO.TeamStatsPrintJob>('/print/team-stats', {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

export const usePrintDepthChart = (): UseMutationResult<JSONResponse<string>, Error, DTO.DepthChartPrintParams> =>
    useMutation((params: DTO.DepthChartPrintParams) =>
        post<string, DTO.DepthChartPrintParams>(`/print/depth-charts`, {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

export const usePrintDraftNight = (): UseMutationResult<JSONResponse<string>, Error, DTO.DraftNightPrintParams> =>
    useMutation((params: DTO.DraftNightPrintParams) =>
        post<string, DTO.DraftNightPrintParams>(`/print/draft-night`, {
            ...params,
            url: process.env.NEXT_PUBLIC_NEXTAUTH_URL,
        })
    )

type MergePagesParams = { files: string[]; folder: string; zip: boolean; title: string; type: Enum.PrintType }

export const useMergePages = (): UseMutationResult<JSONResponse<string>, Error, MergePagesParams> =>
    useMutation((folder: MergePagesParams) => post<string, MergePagesParams>('/print/merge-pages', folder), {
        onSuccess: (d) => {
            window.open(d.data, '_blank')
        },
    })
