'use client'

import type { SelectListItem } from '../../../lib/types/popoverAndDrawerTypes'
import React, { useRef, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '@mui/material/Button'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Collapse, Fade, Grid, IconButton, Paper, Slide } from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'
import Popover from '../../Popover'
import TemporaryDrawer from '../../TemporaryDrawer'
import ImageWithFallback from '../../ImageWithFallback'
import { useBreakPoints } from '../../../lib/hooks'
import ElevateOnScroll from '../../ElevateOnScroll'
import Link from '../../Link'
import SummaryStat from '../../SummaryStat'
import PermissionContent from '../../wrappers/PermissionContent'
import { dateDiff } from '../../../lib/utils/formatters'
import TeamHeaderStats from '@/components/team/profile/TeamHeaderStats'

export const formatTeamName = (
    teamSlug: string | null | undefined,
    teamMarket: string | null | undefined,
    teamName: string | null | undefined,
    displayLink: boolean
): JSX.Element | null =>
    teamSlug && (teamMarket || teamName) && displayLink ? (
        <Link href={`/teams/${teamSlug}/feed`} sx={{ marginRight: 0.5 }}>
            {teamMarket} {teamName}
        </Link>
    ) : teamSlug && (teamMarket || teamName) ? (
        <Box sx={{ marginRight: 0.5 }}>
            {teamMarket} {teamName}
        </Box>
    ) : null

export type AssociatedEntityLink = {
    text: string
    url: string
}
export const formatAssociatedEntityLinks = ({
    matchingStaffEntity,
    matchingAgentEntity,
    matchingPlayerEntity,
}: {
    matchingStaffEntity?: DTO.Entity
    matchingAgentEntity?: DTO.Entity
    matchingPlayerEntity?: DTO.Player
}): AssociatedEntityLink[] => {
    const links = []
    if (matchingStaffEntity) {
        links.push({
            text: 'Staff',
            url: `/staff/${matchingStaffEntity.urlSlug}/feed`,
        })
    }
    if (matchingAgentEntity) {
        links.push({
            text: 'Agent',
            url: `/agents/${matchingAgentEntity.urlSlug}/feed`,
        })
    }
    if (matchingPlayerEntity) {
        links.push({
            text: 'Player',
            url: `/players/${matchingPlayerEntity.urlSlug}/feed`,
        })
    }
    return links
}

export const EntityName = ({
    name,
    isMobile,
    isTablet,
    onClick,
    entityType,
    maxWidth,
}: {
    name: string
    isMobile?: boolean | undefined
    isTablet?: boolean | undefined
    entityType: string | undefined
    onClick?: (event: React.SyntheticEvent) => void
    maxWidth?: string
}): React.ReactElement<{ onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void }, string> => (
    <Typography
        sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.primary',
            border: 'none',
            background: 'none',
            cursor: onClick ? 'pointer' : 'text',
            paddingX: 0,
            height: isMobile ? '24px' : '',
            gap: isMobile ? 0 : 0.5,
            whiteSpace: 'nowrap',
        }}
        variant={isMobile ? 'h6' : 'h5'}
        onClick={onClick}
    >
        {(isMobile || isTablet) && entityType === 'player' ? (
            <Box
                sx={{
                    textOverflow: 'ellipsis',
                    maxWidth: maxWidth || 210,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {name}
            </Box>
        ) : (
            name
        )}

        {onClick && <ArrowDropDownIcon sx={{ color: 'text.secondary', fontSize: '32px', displayPrint: 'none' }} />}
    </Typography>
)

const detailSkeleton = (placeholder: boolean) => (
    <Box
        sx={({ spacing }) => ({
            display: { xs: 'none', sm: 'block' },
            marginLeft: spacing(2),
            paddingLeft: spacing(2),
            borderLeft: '1px solid',
            borderColor: 'divider',
            opacity: placeholder ? 0.3 : 1,
        })}
    >
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Skeleton width={90} height={20} animation={placeholder ? false : 'pulse'} />
            <Skeleton width={20} height={20} animation={placeholder ? false : 'pulse'} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Skeleton width={90} height={20} animation={placeholder ? false : 'pulse'} />
            <Skeleton width={125} height={20} animation={placeholder ? false : 'pulse'} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Skeleton width={90} height={20} animation={placeholder ? false : 'pulse'} />
            <Skeleton width={200} height={20} animation={placeholder ? false : 'pulse'} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Skeleton width={90} height={20} animation={placeholder ? false : 'pulse'} />
            <Skeleton width={100} height={20} animation={placeholder ? false : 'pulse'} />
        </Box>
    </Box>
)

const renderName = (
    isMobile: boolean,
    isTablet: boolean,
    isLoading?: boolean,
    name?: string,
    placeholder?: boolean,
    entityType?: DTO.SearchResultType,
    urlSlug?: string,
    entityListItems?: SelectListItem[] | null,
    maxWidth?: string
) => {
    if (isLoading || placeholder) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', paddingX: 0, gap: 0.5 }}>
                <Skeleton
                    sx={{ width: 190, opacity: placeholder ? 0.3 : 1 }}
                    animation={placeholder ? false : 'pulse'}
                />
                <Skeleton
                    variant="circular"
                    sx={{ opacity: placeholder ? 0.3 : 1 }}
                    width={10}
                    height={10}
                    animation={placeholder ? false : 'pulse'}
                />
            </Box>
        )
    }
    if (isMobile && entityListItems && name) {
        return (
            <TemporaryDrawer
                trigger={<EntityName name={name} isMobile={isMobile} entityType={entityType} maxWidth={maxWidth} />}
                listItems={entityListItems}
                anchor="top"
            />
        )
    }
    if (!isMobile && entityListItems && name) {
        return (
            <Popover
                trigger={<EntityName name={name} isTablet={isTablet} entityType={entityType} />}
                listItems={entityListItems}
                iconList={entityType !== 'staff'}
            />
        )
    }
    if (name && urlSlug && entityType) {
        return (
            <Link href={`/${entityType}s/${urlSlug}/feed`}>
                <EntityName name={name} isMobile={isMobile} isTablet={isTablet} entityType={entityType} />
            </Link>
        )
    }
    if (name) return <EntityName name={name} isMobile={isMobile} isTablet={isTablet} entityType={entityType} />
    return null
}

const renderPlayerList = (players: SelectListItem[], isMobile: boolean) => {
    if (!isMobile)
        return (
            <Popover
                trigger={
                    <IconButton size="small">
                        <GroupIcon />
                    </IconButton>
                }
                listItems={players}
                iconList
            />
        )
    return null
}

export interface EntityDetail {
    label: string
    value: React.ReactNode
}
type EntityHeaderProps = {
    isLoading?: boolean
    name?: string
    secondaryInfo?: React.ReactNode
    tertiaryInfo?: React.ReactNode
    detail?: EntityDetail[]
    entityListItems?: SelectListItem[] | null
    image?: {
        src?: string | null
        aspectRatio: number
        fallbackIcon?: JSX.Element
    }
    placeholder?: boolean
    entityType?: DTO.SearchResultType
    urlSlug?: string
    isVisible: boolean
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
    isMobileHeaderVisible?: boolean
    setIsMobileHeaderVisible?: React.Dispatch<React.SetStateAction<boolean>>
    summary?: DTO.PlayerSummary[]
    isSummaryLoading?: boolean
    playerWorkoutTracker?: DTO.PlayerSummary[]
    associatedEntityLinks?: AssociatedEntityLink[] | null
    teamStats?: DTO.TeamBasicStats
    players?: SelectListItem[]
}

export const entityHeaderHeight = 100

const EntityHeader = ({
    isLoading,
    image,
    name,
    secondaryInfo,
    tertiaryInfo,
    detail,
    entityListItems,
    placeholder,
    entityType,
    urlSlug,
    isVisible,
    setIsVisible,
    isMobileHeaderVisible,
    setIsMobileHeaderVisible,
    summary,
    isSummaryLoading,
    playerWorkoutTracker,
    associatedEntityLinks,
    teamStats,
    players,
}: EntityHeaderProps): JSX.Element | null => {
    const [isDetailIconVisible, setIsDetailIconVisible] = React.useState<boolean>(false)
    const headerRef = useRef<HTMLDivElement>(null)
    const detailRef = useRef<HTMLDivElement>(null)
    const { isMobile, isTablet, isPrint } = useBreakPoints()
    const showSecondaryAndTertiaryInfo = isMobile && (entityType === 'player' || entityType === 'staff')

    const entityHeaderWidth = window.innerWidth
    const mobileHeaderHeight = 60
    const maxWidth = `${(entityHeaderWidth - 145) * 0.9}px`

    useEffect(() => {
        if (isMobile) setIsVisible(true)
        if (!isMobile && setIsMobileHeaderVisible) setIsMobileHeaderVisible(false)
        setIsDetailIconVisible(isMobile || isPrint)
    }, [isMobile, isPrint, setIsVisible, setIsMobileHeaderVisible, setIsDetailIconVisible])

    return (
        <ElevateOnScroll elevationHeight={3}>
            <AppBar
                className="entity-header"
                sx={{
                    position: 'sticky',
                    backgroundColor: 'common.white',
                    top: '48px',
                    paddingY: 0.5,
                    borderBottom: 1,
                    borderColor: 'divider',
                    '@media print': { boxShadow: 0 },
                    displayPrint: entityType === 'team' ? 'none' : undefined,
                }}
            >
                {!isVisible && (
                    <Box sx={{ display: 'flex', paddingX: 3, height: '36px' }}>
                        <Box
                            onClick={() => setIsVisible(true)}
                            sx={{ marginLeft: 'auto', display: { xs: 'none', sm: 'flex' } }}
                        >
                            <Button size="small" endIcon={<ExpandMoreIcon />}>
                                Show
                            </Button>
                        </Box>
                    </Box>
                )}
                <Box sx={{ display: 'none', displayPrint: 'block', marginTop: '-48px' }} />
                <Toolbar
                    sx={{
                        display: isMobile && isVisible ? 'flex' : isVisible ? 'flex' : 'none',
                        height: { xs: mobileHeaderHeight, sm: entityHeaderHeight - 9 },
                        justifyContent: isMobile ? 'space-between' : 'inherit',
                    }}
                    ref={headerRef}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {isLoading || placeholder ? (
                                    <Skeleton
                                        sx={{
                                            width: { xs: 60, sm: entityHeaderHeight - 15 },
                                            height: { xs: 60, sm: entityHeaderHeight - 15 },
                                            opacity: placeholder ? 0.3 : 1,
                                            marginRight: '16px',
                                        }}
                                        variant="circular"
                                        animation={placeholder ? false : 'pulse'}
                                    />
                                ) : (
                                    image && (
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                display: 'flex',
                                                alignSelf: 'baseline',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: '16px',
                                                width: {
                                                    xs: 69 * image.aspectRatio,
                                                    sm: entityHeaderHeight * image.aspectRatio,
                                                },
                                                height: { xs: 69, sm: entityHeaderHeight },
                                            }}
                                        >
                                            : (
                                            <ImageWithFallback
                                                src={image.src}
                                                alt="entity image"
                                                fallbackSize={{ xs: 60, sm: entityHeaderHeight - 15 }}
                                                priority
                                                sizes="100%"
                                            >
                                                {image.fallbackIcon}
                                            </ImageWithFallback>
                                            )
                                        </Box>
                                    )
                                )}
                                <Box sx={{ color: 'text.primary' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {renderName(
                                                isMobile,
                                                isTablet,
                                                isLoading,
                                                name,
                                                placeholder,
                                                entityType,
                                                urlSlug,
                                                entityListItems,
                                                maxWidth
                                            )}
                                        </Box>
                                        {entityType === 'team' && isLoading && (
                                            <Skeleton
                                                variant="circular"
                                                sx={{ marginLeft: 1 }}
                                                width={10}
                                                height={10}
                                            />
                                        )}
                                        {entityType === 'team' && !!players?.length && !isLoading && (
                                            <Box>{renderPlayerList(players, isMobile)}</Box>
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: showSecondaryAndTertiaryInfo ? 'flex' : 'none',
                                                sm: 'flex',
                                                alignItems: 'baseline',
                                                fontSize: showSecondaryAndTertiaryInfo ? '14px' : 'inherit',
                                                '@media print': { fontSize: '18px' },
                                            },
                                            color: 'text.secondary',
                                        }}
                                    >
                                        {isLoading || placeholder ? (
                                            <Skeleton
                                                sx={{ width: 190, opacity: placeholder ? 0.3 : 1 }}
                                                animation={placeholder ? false : 'pulse'}
                                            />
                                        ) : (
                                            secondaryInfo
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: showSecondaryAndTertiaryInfo ? 'flex' : 'none',
                                                sm: 'flex',
                                                gap: '4px',
                                                alignItems: 'baseline',
                                                fontSize: showSecondaryAndTertiaryInfo ? '14px' : 'inherit',
                                                '@media print': { fontSize: '18px' },
                                            },
                                            color: 'text.secondary',
                                        }}
                                    >
                                        {isLoading || placeholder ? (
                                            <Skeleton
                                                width={190}
                                                sx={{ opacity: placeholder ? 0.3 : 1 }}
                                                animation={placeholder ? false : 'pulse'}
                                            />
                                        ) : (
                                            tertiaryInfo
                                        )}
                                    </Box>
                                </Box>
                                {isDetailIconVisible && !!detail?.length && !isLoading && setIsMobileHeaderVisible && (
                                    <Fade in={!isMobileHeaderVisible} exit>
                                        <IconButton
                                            onClick={() => setIsMobileHeaderVisible(!isMobileHeaderVisible)}
                                            disabled={isLoading}
                                            sx={{
                                                displayPrint: 'none',
                                                width: '25px',
                                                position: 'absolute',
                                                right: '8px',
                                                bottom: '-5px',
                                            }}
                                            size="small"
                                        >
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Fade>
                                )}
                            </Box>
                            {!!detail?.length && !isLoading && (
                                <Box
                                    sx={({ spacing }) => ({
                                        display: { xs: 'none', print: 'flex' },
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        marginLeft: spacing(2),
                                        paddingLeft: spacing(2),
                                        borderLeft: '1px solid',
                                        borderColor: 'divider',
                                        table: {
                                            lineHeight: 1.15,
                                        },
                                    })}
                                    ref={detailRef}
                                >
                                    <table>
                                        <tbody>
                                            {detail.map(({ label, value }) => (
                                                <tr key={label}>
                                                    <td>
                                                        <Box>
                                                            <Typography
                                                                sx={{
                                                                    color: 'text.secondary',
                                                                    lineHeight: 1.15,
                                                                    fontWeight: 'normal',
                                                                    '@media print': { fontSize: '16px' },
                                                                    whiteSpace: 'nowrap',
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                {label}
                                                            </Typography>
                                                        </Box>
                                                    </td>
                                                    <td>
                                                        <Box
                                                            sx={({ spacing }) => ({
                                                                paddingLeft: spacing(1),
                                                            })}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: 'text.primary',
                                                                    lineHeight: 1.15,
                                                                    fontWeight: 'normal',
                                                                    '@media print': {
                                                                        fontSize: '16px',
                                                                        textOverflow: 'ellipsis',
                                                                        maxWidth: '350px',
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                    },
                                                                }}
                                                                variant="subtitle2"
                                                            >
                                                                {value}
                                                            </Typography>
                                                        </Box>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Box>
                            )}
                        </Box>
                        <Box
                            display={{ xs: 'none', lg: 'flex' }}
                            displayPrint="flex"
                            sx={{
                                justifyContent: 'flex-start',
                                alignItems: 'flex-end',
                                height: entityHeaderHeight,
                                paddingY: 1.5,
                                WebkitPrintColorAdjust: 'exact',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                gap: 2,
                            }}
                        >
                            {entityType === 'team' && !!(teamStats || isSummaryLoading) && (
                                <TeamHeaderStats stats={teamStats} alignVertical isLoading={!!isSummaryLoading} />
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    columnGap: 1,
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    height: '100%',
                                }}
                            >
                                <PermissionContent type="player-draft-info">
                                    {isSummaryLoading && entityType === 'player'
                                        ? [1, 2].map((d) => (
                                              <SummaryStat
                                                  key={d}
                                                  label=""
                                                  value={null}
                                                  isLoading
                                                  height="35px"
                                                  width={140}
                                                  emptyVal=""
                                              />
                                          ))
                                        : playerWorkoutTracker?.map((d) => (
                                              <SummaryStat
                                                  key={d.label}
                                                  label={d.label}
                                                  value={d.value}
                                                  isLoading={false}
                                                  height="35px"
                                                  width={140}
                                                  type={d.value ? 'success' : 'info'}
                                                  fontSize={d.value ? 16 : undefined}
                                                  textAlign="center"
                                              />
                                          ))}
                                </PermissionContent>
                                {isSummaryLoading
                                    ? (entityType === 'team' ? [1, 2] : [1, 2, 3, 4]).map((d) => (
                                          <SummaryStat
                                              key={d}
                                              label=""
                                              value={null}
                                              isLoading
                                              width={140}
                                              height="35px"
                                              emptyVal=""
                                          />
                                      ))
                                    : summary?.map((d) => (
                                          <SummaryStat
                                              key={d.label}
                                              label={d.label}
                                              value={d.value}
                                              labelColor={
                                                  d.date && dateDiff(d.date, 'month', 9) ? 'error.main' : 'text.primary'
                                              }
                                              type={
                                                  d.calloutKey === 'netRating'
                                                      ? String(d.value).startsWith('+')
                                                          ? 'success'
                                                          : 'alert'
                                                      : undefined
                                              }
                                              width={140}
                                              textAlign="center"
                                              isLoading={false}
                                              height="35px"
                                          />
                                      ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: '8px',
                            height: '100%',
                        }}
                    >
                        <Button
                            onClick={() => setIsVisible(!isVisible)}
                            sx={{ displayPrint: 'none', marginBottom: 'auto' }}
                            size="small"
                            endIcon={<ExpandLessIcon />}
                        >
                            {isVisible ? 'Hide' : 'Show'}
                        </Button>
                        {associatedEntityLinks?.map(({ text, url }) => (
                            <Link
                                href={url}
                                key={url}
                                sx={{ whiteSpace: 'nowrap', fontSize: 14, marginRight: { sm: 1, md: 0 } }}
                            >
                                View {text} Page
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
                {setIsMobileHeaderVisible && (
                    <Collapse in={isMobileHeaderVisible} timeout="auto" unmountOnExit>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingLeft: 2,
                                paddingRight: 2,
                                paddingTop: 1,
                                height: '100px',
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    fontSize: isMobile ? '14px' : 'inherit',
                                    color: 'text.secondary',
                                }}
                            >
                                {detail?.map(({ label, value }) => (
                                    <Grid item container xs={12} key={label}>
                                        <Grid item xs={4}>
                                            <Typography
                                                sx={{
                                                    color: 'text.secondary',
                                                    lineHeight: 1.15,
                                                    fontWeight: 'normal',
                                                }}
                                                variant="subtitle2"
                                            >
                                                {label}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography
                                                sx={{
                                                    color: 'text.primary',
                                                    lineHeight: 1.15,
                                                    fontWeight: 'normal',
                                                }}
                                                variant="subtitle2"
                                            >
                                                {value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        width: `${entityHeaderWidth - 50}px`,
                                        overflow: 'auto',
                                        columnGap: 0.5,
                                        marginTop: 0.5,
                                    }}
                                >
                                    {[...(summary || []), ...(playerWorkoutTracker || [])].map((d) => (
                                        <Paper
                                            key={d.label}
                                            sx={({ spacing, palette }) => ({
                                                backgroundColor:
                                                    d.calloutKey === 'workout' || d.calloutKey === 'interview'
                                                        ? palette.callOut.success
                                                        : palette.callOut.info,
                                                padding: spacing(0.25, 0.5),
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                overflow: 'hidden',
                                                gap: 0.5,
                                                minWidth: '110px',
                                                maxWidth: '110px',
                                            })}
                                            elevation={0}
                                        >
                                            <Box sx={{ fontSize: 12, fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                                {d.value || 'N/A'}
                                            </Box>
                                            <Box
                                                sx={{
                                                    fontSize: 10,
                                                    color: 'text.secondary',
                                                    textAlign: 'center',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    maxWidth: '65px',
                                                    minWidth: '50px',
                                                }}
                                            >
                                                {d.label}
                                            </Box>
                                        </Paper>
                                    ))}
                                </Box>
                            </Grid>
                            {(isLoading || placeholder) && detailSkeleton(!!placeholder)}
                            <Slide
                                direction="down"
                                in={isMobileHeaderVisible}
                                mountOnEnter
                                unmountOnExit
                                container={headerRef.current}
                            >
                                <IconButton
                                    onClick={() => setIsMobileHeaderVisible(!isMobileHeaderVisible)}
                                    disabled={isLoading}
                                    sx={{
                                        displayPrint: 'none',
                                        width: '25px',
                                        position: 'absolute',
                                        right: '8px',
                                        bottom: '-5px',
                                    }}
                                    size="small"
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Slide>
                        </Toolbar>
                    </Collapse>
                )}
            </AppBar>
        </ElevateOnScroll>
    )
}
export default EntityHeader
