import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

const apiPath = '/games'

export type GameQueryParams = RequestParams & {
    teamIds?: string[]
    gameDate?: string
    leagues?: Enum.League[]
    startDate?: string
    endDate?: string
    season?: string
    status?: Enum.GameStatus[]
}

export const queryGames = async (params?: GameQueryParams): Promise<DTO.Game[]> =>
    (await get<DTO.Game[]>(`${apiPath}`, params)).data

export const useQueryGames = ({
    params,
    options,
}: {
    params?: GameQueryParams
    options?: Omit<UseQueryOptions<DTO.Game[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
}): UseQueryResult<DTO.Game[]> =>
    useQuery<DTO.Game[]>(['games', params], () => queryGames(params), { enabled: options?.enabled })

export const serverGetGameByGameId = async (gameId: string, session: Session | null): Promise<DTO.Game> =>
    (await serverGet<DTO.Game>(`${apiPath}/${gameId})`, session)).data

export const getGameByGameId = async (gameId: string): Promise<DTO.Game> =>
    (await get<DTO.Game>(`${apiPath}/${gameId})`)).data

export const useGetGameByGameId = (gameId: string | undefined): UseQueryResult<DTO.Game> =>
    // @ts-expect-error gameId will only get referenced here when it is defined (enabled)
    useQuery<DTO.Game>(['games', gameId], () => getGameByGameId(gameId), { enabled: !!gameId })

export type BoardPlayersQueryParams = RequestParams & {
    startDate?: string
    endDate?: string
    gameId?: string
    assignmentId?: string
    getPrimary?: boolean
    scoutId?: string
    boardId?: string
    viewing?: string
}

export const queryBoardPlayers = async (params: BoardPlayersQueryParams): Promise<DTO.GameBoardPlayer[]> =>
    (await get<DTO.GameBoardPlayer[]>(`${apiPath}/board-players`, params)).data

export const useQueryBoardPlayers = ({
    params,
    options,
}: {
    params: BoardPlayersQueryParams
    options?: Omit<
        UseQueryOptions<DTO.GameBoardPlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.GameBoardPlayer[]> =>
    useQuery<DTO.GameBoardPlayer[]>(['board-players', params], () => queryBoardPlayers(params), options)
