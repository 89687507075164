import type TableConfig from '../../lib/types/tableConfigTypes'
import type { PlayerSeasonBoxscoreStats } from '@/lib/hooks/usePlayerSeasonBoxscoreStats'
import React from 'react'
import { formatNumber } from '../../lib/utils/math'
import Table from '../Table'
import { formatSeason } from '@/lib/utils/formatters'

export type StatCategories = {
    [column in keyof PlayerSeasonBoxscoreStats]?: boolean
}

type PlayerSeasonBoxscoreProps = {
    boxscores: PlayerSeasonBoxscoreStats[] | undefined
    isLoading?: boolean
    overrideStatCategories?: (keyof StatCategories)[]
    hideHeaders?: boolean
}

const defaultStatCategories: StatCategories & { plusMinus: boolean } = {
    team: true,
    ppg: true,
    mpg: true,
    '2fg%': true,
    '3fg%': true,
    'ft%': true,
    rbpg: true,
    astpg: true,
    orbpg: true,
    drbpg: true,
    tovpg: true,
    stlpg: true,
    blkpg: true,
    gp: true,
    gs: true,
    pfpg: true,
    plusMinus: true,
    type: true,
}

export const formatSeasonType = (x: PlayerSeasonBoxscoreStats): string => {
    if (!x.season || !x.team || !x.type) return ''

    const season = formatSeason(x.season)
    const { team } = x
    const type = x.type !== 'REGULAR' ? ` / ${x.type.slice(0, 4)}.` : ''
    const league = x.league === 'Summer' ? `${season} / ${team} / SUMM.` : `${season} / ${team}${type}`

    return league
}
export const seasonStatsFields: Array<TableConfig<PlayerSeasonBoxscoreStats>['fields'][number]> = [
    {
        key: 'team',
        label: 'SEASON',
        select: (x) => formatSeasonType(x),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 140, minWidth: 140, maxWidth: 140, whiteSpace: 'nowrap' },
        skeletonStyle: { xs: 100 },
    },
    {
        key: 'gp',
        label: 'GP',
        select: (x) => x.gp,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'gs',
        label: 'GS',
        select: (x) => x.gs,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'mpg',
        label: 'MPG',
        select: (x) => formatNumber(x.mpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'ppg',
        label: 'PPG',
        select: (x) => formatNumber(x.ppg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },

    {
        key: '2fg%',
        label: '2P%',
        select: (x) => x['2fg%'] && formatNumber(x['2fg%'] * 100),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: '3fg%',
        label: '3P%',
        select: (x) => x['3fg%'] && formatNumber(x['3fg%'] * 100),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'ft%',
        label: 'FT%',
        select: (x) => x['ft%'] && formatNumber(x['ft%'] * 100),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'rbpg',
        label: 'REB',
        select: (x) => formatNumber(x.rbpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'orbpg',
        label: 'OREB',
        select: (x) => formatNumber(x.orbpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'drbpg',
        label: 'DREB',
        select: (x) => formatNumber(x.drbpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'astpg',
        label: 'AST',
        select: (x) => formatNumber(x.astpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'stlpg',
        label: 'STL',
        select: (x) => formatNumber(x.stlpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'blkpg',
        label: 'BLK',
        select: (x) => formatNumber(x.blkpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'tovpg',
        label: 'TO',
        select: (x) => formatNumber(x.tovpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'pfpg',
        label: 'PF',
        select: (x) => formatNumber(x.pfpg),
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
    {
        key: 'plusMinus',
        label: '',
        select: () => null,
        headCellFormat: { paddingY: 0, paddingX: '10px', color: 'text.secondary' },
        format: { paddingX: '10px', width: 50, maxWidth: 50, minWidth: 50 },
        numeric: true,
        skeletonStyle: { xs: 40 },
    },
]

const tableConfig = (overrideStatCategories?: (keyof StatCategories)[]): TableConfig<PlayerSeasonBoxscoreStats> => {
    const statCategories: StatCategories = overrideStatCategories
        ? overrideStatCategories.reduce(
              (acc, s) => ({
                  ...acc,
                  [s]: true,
              }),
              {}
          )
        : defaultStatCategories

    const fields = seasonStatsFields.filter(({ key }) => statCategories[key as keyof StatCategories])

    return {
        loadingSkeleton: {
            numOfRows: 1,
            height: 30,
        },
        fields,
    }
}

const PlayerSeasonBoxscore = ({
    boxscores,
    isLoading,
    overrideStatCategories,
    hideHeaders = false,
}: PlayerSeasonBoxscoreProps): JSX.Element => (
    <Table<PlayerSeasonBoxscoreStats>
        rows={boxscores || []}
        config={tableConfig(overrideStatCategories)}
        isLoading={isLoading}
        hideSorting
        getRowKey={(r) => `${r.gp}-${r.gs}`}
        emptyValue="-"
        hover={false}
        hideHeaders={hideHeaders}
    />
)

export default PlayerSeasonBoxscore
