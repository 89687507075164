import { useMemo } from 'react'

const levelToLeague = (level: Enum.PlayerLevel): Enum.League => (level === 'AM' ? 'College' : 'NBA')

const useShotChartDefaultLeague = (
    playerBio: DTO.PlayerBio | undefined,
    player: DTO.Player | undefined,
    levelOverride?: Enum.PlayerLevel | null
): Enum.League | undefined => {
    const teamLeague = playerBio?.teamLeague
    const playerLevel = player?.level
    return useMemo<Enum.League | undefined>(() => {
        // if the player is currently on an international team, assume the amateur shot chart is also international
        if (levelOverride === 'AM' && teamLeague === 'International') return teamLeague
        // if the player is currently on a G-League team, assume the shot chart is G-League
        if (teamLeague === 'G-League') return teamLeague
        if (levelOverride) return levelToLeague(levelOverride)
        if (teamLeague && teamLeague !== 'All-Star') return teamLeague
        return playerLevel ? levelToLeague(playerLevel) : undefined
    }, [teamLeague, playerLevel, levelOverride])
}

export default useShotChartDefaultLeague
