import type { SelectChangeEvent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import useToastContext from '../../lib/hooks/useToastContext'
import { useAddItemToWatchList } from '../../lib/hooks/useUserWatchList'
import { mapSearchResultToTag, mapTagToSearchResult } from '../../lib/utils/search'
import SelectBoard from '../form/SelectBoard'
import MultipleSelectSearch from '../search/MultipleSelectSearch'

type AddPlayerAndBoardProps = {
    boardLevels: Enum.BoardLevels[]
    initialValues?: {
        boards: string[]
        players: DTO.PostTag[]
    }
    handleDiscard: () => void
}

const AddPlayerAndBoardForm = ({ boardLevels, initialValues, handleDiscard }: AddPlayerAndBoardProps): JSX.Element => {
    const toastContext = useToastContext()
    const { mutate: submit } = useAddItemToWatchList()

    const getSubmitValues = (formikValues: {
        players: DTO.PostTag[]
        boards: string[]
    }): { players: string[]; boards: string[] } => ({
        players: formikValues.players.map((x) => x.entityId),
        boards: formikValues.boards,
    })

    const formik = useFormik<{ players: DTO.PostTag[]; boards: string[] }>({
        initialValues: {
            players: initialValues?.players ? initialValues.players : [],
            boards: initialValues?.boards ? initialValues.boards : [],
        },
        onSubmit: (values, { setSubmitting }) => {
            const submitValues = getSubmitValues(values)

            submit(submitValues, {
                onSuccess: () => {
                    toastContext?.addToast({
                        severity: 'success',
                        message: 'Watch List Updated',
                    })
                    handleDiscard()
                },
                onError: (error) => {
                    toastContext?.addToast({
                        severity: 'error',
                        message: error.message,
                    })
                    setSubmitting(false)
                },
            })
        },
    })

    const onPlayerChange = useCallback(
        (value: DTO.SearchResult[]) => {
            const players = value.map(mapSearchResultToTag)
            return formik.setFieldValue('players', players)
        },
        [formik]
    )

    const onBoardChange = useCallback(
        (e: SelectChangeEvent<unknown>) => formik.setFieldValue('boards', e.target.value),
        [formik]
    )

    return (
        <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
                <DialogTitle>Update Watch List</DialogTitle>
                <DialogContent sx={{ maxWidth: '100%' }}>
                    <Grid item sx={{ padding: 2 }} xs={12}>
                        <MultipleSelectSearch
                            autoFocus
                            searchType="player"
                            value={formik.values.players.map(mapTagToSearchResult)}
                            onChange={onPlayerChange}
                            placeholder="Players"
                            allowEntityCreation={false}
                        />
                    </Grid>
                    <Grid item sx={{ padding: 2 }} xs={12}>
                        <SelectBoard
                            onChange={onBoardChange}
                            value={formik.values.boards}
                            level={boardLevels}
                            multiple
                            fullWidth
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid item container direction="row-reverse" spacing={2}>
                        <Grid item>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting}
                            >
                                Submit
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleDiscard} disabled={formik.isSubmitting}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Grid>
        </form>
    )
}

type WatchListDialogProps = {
    isOpen: boolean
    boardLevels: Enum.BoardLevels[]
    initialValues:
        | {
              boards: string[]
              players: DTO.PostTag[]
          }
        | undefined
    handleDialogClose: () => void
}

const AddWatchListItemDialog = ({
    isOpen,
    boardLevels,
    initialValues,
    handleDialogClose,
}: WatchListDialogProps): JSX.Element => (
    <Dialog fullWidth open={isOpen} onClose={handleDialogClose}>
        {isOpen && (
            <AddPlayerAndBoardForm
                boardLevels={boardLevels}
                initialValues={initialValues}
                handleDiscard={handleDialogClose}
            />
        )}
    </Dialog>
)

export default AddWatchListItemDialog
