import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getSearchResults = async (q: string, types?: DTO.SearchResultType[]): Promise<DTO.SearchResult[]> =>
    (await get<DTO.SearchResult[]>('/search', { q, types })).data

const useSearch = (q: string, types?: DTO.SearchResultType[]): UseQueryResult<DTO.SearchResult[]> =>
    useQuery<DTO.SearchResult[]>(['search', q, types], () => getSearchResults(q, types), {
        enabled: !!q && q.length >= 3, // If we have searchQuery, then enable the query on render
    })

export const useDXPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.DraftExpressPlayer[]> =>
    useQuery<DTO.DraftExpressPlayer[]>(
        ['search-dx', q],
        async () => (await get<DTO.DraftExpressPlayer[]>('/search/dx', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useNBAPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.NBAPlayer[]> =>
    useQuery<DTO.NBAPlayer[]>(['search-nba', q], async () => (await get<DTO.NBAPlayer[]>('/search/nba', { q })).data, {
        enabled: !!q && q.length >= 4,
    })

export const useSportradarPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.SportradarPlayer[]> =>
    useQuery<DTO.SportradarPlayer[]>(
        ['search-sportradar', q],
        async () => (await get<DTO.SportradarPlayer[]>('/search/sportradar', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useSynergyPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.SynergyPlayer[]> =>
    useQuery<DTO.SynergyPlayer[]>(
        ['search-synergy', q],
        async () => (await get<DTO.SynergyPlayer[]>('/search/synergy', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useRealGMPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.RealGMPlayer[]> =>
    useQuery<DTO.RealGMPlayer[]>(
        ['search-realgm', q],
        async () => (await get<DTO.RealGMPlayer[]>('/search/realgm', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useRotoWirePlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.RotoWirePlayer[]> =>
    useQuery<DTO.RotoWirePlayer[]>(
        ['search-rotowire', q],
        async () => (await get<DTO.RotoWirePlayer[]>('/search/rotowire', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useNoahPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.NoahPlayer[]> =>
    useQuery<DTO.NoahPlayer[]>(
        ['search-noah', q],
        async () => (await get<DTO.NoahPlayer[]>('/search/noah', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export const useESPNPlayerSearch = (q: string | null | undefined): UseQueryResult<DTO.ESPNPlayer[]> =>
    useQuery<DTO.ESPNPlayer[]>(
        ['search-espn', q],
        async () => (await get<DTO.ESPNPlayer[]>('/search/espn', { q })).data,
        {
            enabled: !!q && q.length >= 4,
        }
    )

export default useSearch
