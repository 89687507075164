import React from 'react'
import Stack from '@mui/material/Stack'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import GroupsIcon from '@mui/icons-material/Groups'
import Divider from '@mui/material/Divider'
import { Skeleton } from '@mui/material'
import ImageWithFallback from '../ImageWithFallback'
import TimeLocationDetails from './TimeLocationDetails'
import { formatDateString } from '@/lib/utils/formatters'

type GameDetailsHeaderProps = {
    event:
        | (Pick<
              DTO.AssignmentCalendarExtendedProps,
              | 'awayTeamLogoUrl'
              | 'awayTeamMarket'
              | 'awayTeamScore'
              | 'homeTeamLogoUrl'
              | 'homeTeamMarket'
              | 'homeTeamScore'
              | 'venue'
              | 'gameTimeUtc'
          > & {
              status: DTO.AssignmentCalendarExtendedProps['gameStatus'] | null
              awayTeamAbbr: DTO.AssignmentCalendarExtendedProps['awayTeamAbbr']
              awayTeamName: DTO.AssignmentCalendarExtendedProps['awayTeamName']
              homeTeamAbbr: DTO.AssignmentCalendarExtendedProps['homeTeamAbbr']
              homeTeamName: DTO.AssignmentCalendarExtendedProps['homeTeamName']
              date: DTO.AssignmentCalendarExtendedProps['date']
          })
        | undefined
    isLandscapeOrDesktop: boolean
    isFutureEvent: boolean
    isPostView?: boolean
    teamStandings?: {
        homeTeamWins: number
        homeTeamLosses: number
        awayTeamWins: number
        awayTeamLosses: number
    } | null
    isLoading?: boolean
}

const GameDetailsHeader = ({
    event,
    isFutureEvent,
    isLandscapeOrDesktop,
    isPostView,
    teamStandings,
    isLoading,
}: GameDetailsHeaderProps): JSX.Element => (
    <Grid container>
        <Grid
            item
            container
            xs={5}
            md={isFutureEvent ? 4 : 5}
            sx={{
                alignItems: { xs: 'center' },
                justifyContent: { xs: 'center' },
            }}
            paddingLeft={{ xs: isFutureEvent ? 2 : 0 }}
        >
            <Box
                sx={{
                    alignItems: { xs: 'center' },
                    justifyContent: { xs: 'left' },
                }}
            >
                <Stack direction="row-reverse">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: { xs: !isFutureEvent ? 0 : 2, sm: 0 },
                        }}
                    >
                        {event &&
                            event.status === 'FINISHED' &&
                            event.homeTeamScore &&
                            event.awayTeamScore &&
                            !isLoading && (
                                <Typography
                                    color={
                                        event.awayTeamScore > event.homeTeamScore ? 'text.primary' : 'text.secondary'
                                    }
                                    variant="h5"
                                    fontSize={{ xs: '18px', sm: '22px' }}
                                >
                                    {event.awayTeamScore}
                                </Typography>
                            )}

                        {isLoading && !isFutureEvent && <Skeleton width={50} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'left',
                                padding: { xs: 0, sm: 1 },
                                paddingBottom: { xs: 1 },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    height: { xs: 35, md: 50 },
                                    minWidth: { xs: 35, md: 50 },
                                    alignItems: 'center',
                                }}
                            >
                                <ImageWithFallback
                                    src={event?.awayTeamLogoUrl}
                                    alt="away team logo"
                                    fallbackSize={{ xs: 35, md: 50 }}
                                    priority
                                >
                                    <GroupsIcon />
                                </ImageWithFallback>
                            </Box>
                        </Box>

                        <Stack direction="column" spacing={-1} justifyContent="center" alignItems="right">
                            <Typography
                                variant="overline"
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                }}
                                textAlign="right"
                                justifyContent="right"
                            >
                                Away
                            </Typography>
                            {!isLoading && (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        lineHeight: '20px',
                                        fontSize: { xs: '14px', sm: '18px' },
                                        minWidth: { xs: undefined, lg: isPostView ? undefined : '125px' },
                                        textAlign: { xs: 'center', sm: 'right' },
                                    }}
                                >
                                    {isLandscapeOrDesktop
                                        ? `${event?.awayTeamMarket || ''} ${event?.awayTeamName || ''}`
                                        : `${event?.awayTeamAbbr || ''}`}
                                </Typography>
                            )}
                            {isLoading && <Skeleton width={100} />}
                            {teamStandings && (
                                <Typography variant="overline" padding={0} textAlign="left">
                                    {`${teamStandings.awayTeamWins}-${teamStandings.awayTeamLosses}`}
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Grid>
        {!isFutureEvent && (
            <Grid item container xs={2} justifyContent={{ xs: 'center' }}>
                <Box
                    sx={{
                        marginY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                        bottom: '8px',
                    }}
                >
                    <Box lineHeight={1}>
                        {event?.gameTimeUtc ? (
                            <Typography color="text.secondary" variant="overline" textAlign="center" lineHeight={1}>
                                {formatDateString(event.gameTimeUtc, 'M/D/YY', 'eastern')}
                            </Typography>
                        ) : event?.date ? (
                            <Typography color="text.secondary" variant="overline" textAlign="center" lineHeight={1}>
                                {formatDateString(event.date, 'M/D/YY', 'utc')}
                            </Typography>
                        ) : (
                            <Skeleton width={50} />
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        {event?.awayTeamScore && event.homeTeamScore ? (
                            event.awayTeamScore > event.homeTeamScore ? (
                                <ArrowLeftIcon sx={{ position: 'absolute', left: -30 }} />
                            ) : (
                                <ArrowRightIcon sx={{ position: 'absolute', right: -30 }} />
                            )
                        ) : null}
                        {event && <Typography variant="subtitle2">Final</Typography>}
                    </Box>
                </Box>
            </Grid>
        )}
        {isFutureEvent && (
            <Grid item container xs={2} md={4} justifyContent={{ xs: 'center', md: isPostView ? 'center' : 'center' }}>
                <TimeLocationDetails isLoading={!!isLoading} event={event} />
            </Grid>
        )}
        <Grid
            item
            container
            xs={5}
            md={isFutureEvent ? 4 : 5}
            sx={{
                alignItems: { xs: 'center' },
                justifyContent: { xs: 'center' },
            }}
            paddingRight={{ xs: isFutureEvent ? 2 : 0 }}
        >
            <Box
                sx={{
                    alignItems: { xs: 'center' },
                    justifyContent: { xs: 'left' },
                }}
            >
                <Stack direction="row">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: { xs: !isFutureEvent ? 0 : 2, sm: 0 },
                        }}
                    >
                        {event &&
                            event.status === 'FINISHED' &&
                            event.homeTeamScore &&
                            event.awayTeamScore &&
                            !isLoading && (
                                <Typography
                                    color={
                                        event.homeTeamScore > event.awayTeamScore ? 'text.primary' : 'text.secondary'
                                    }
                                    variant="h5"
                                    fontSize={{ xs: '18px', sm: '22px' }}
                                >
                                    {event.homeTeamScore}
                                </Typography>
                            )}
                        {isLoading && !isFutureEvent && <Skeleton width={50} />}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'right',
                                padding: { xs: 0, sm: 1 },
                                paddingBottom: { xs: 1 },
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    height: { xs: 35, md: 50 },
                                    minWidth: { xs: 35, md: 50 },
                                    alignItems: 'center',
                                }}
                            >
                                <ImageWithFallback
                                    src={event?.homeTeamLogoUrl}
                                    alt="home team logo"
                                    fallbackSize={{ xs: 35, md: 50 }}
                                    priority
                                >
                                    <GroupsIcon />
                                </ImageWithFallback>
                            </Box>
                        </Box>
                        <Stack direction="column" spacing={-1} justifyContent="center">
                            <Typography
                                variant="overline"
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                }}
                                textAlign="right"
                            >
                                Home
                            </Typography>
                            {!isLoading && (
                                <Typography
                                    variant="h6"
                                    sx={{
                                        lineHeight: '20px',
                                        fontSize: { xs: '14px', sm: '18px' },
                                        minWidth: { xs: undefined, lg: isPostView ? undefined : '125px' },
                                        textAlign: { xs: 'center', sm: 'left' },
                                    }}
                                >
                                    {isLandscapeOrDesktop
                                        ? `${event?.homeTeamMarket || ' '} ${event?.homeTeamName || ''}`
                                        : `${event?.homeTeamAbbr || ' '}`}
                                </Typography>
                            )}
                            {isLoading && <Skeleton width={100} />}
                            {teamStandings && (
                                <Typography variant="overline" padding={0} top={0} textAlign="right">
                                    {`${teamStandings.homeTeamWins}-${teamStandings.homeTeamLosses}`}
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </Grid>
        <Divider
            sx={{
                display: {
                    xs: 'block',
                    sm: isPostView ? 'block' : 'none',
                    md: 'none',
                    lg: 'none',
                },
                width: '100%',
                height: '100%',
                marginY: 1,
            }}
        />
    </Grid>
)

export default GameDetailsHeader
