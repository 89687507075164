import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { BoardPlayersQueryParams } from './useGames'
import type { Session } from 'next-auth'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { post, get, put, serverGet } from '../api'

export const useAddAssignment = ({
    params,
    boardParams,
}: {
    params?: ScoutAssignmentQueryParams
    boardParams?: BoardPlayersQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.AddAssignment[]> => {
    const queryClient = useQueryClient()
    return useMutation(
        (assignments: DTO.AddAssignment[]) => post<undefined, DTO.AddAssignment[]>('/scout-assignments', assignments),
        {
            onSettled: async () => {
                void queryClient.invalidateQueries(['board-players', boardParams])
                await queryClient.invalidateQueries(['assignment-widget'])
                await queryClient.invalidateQueries(['scout-assignments-calendar', params])
            },
        }
    )
}

export const useAddEventGames = ({
    params,
    boardParams,
}: {
    params?: ScoutAssignmentQueryParams
    boardParams?: BoardPlayersQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.AddAssignment[]> => {
    const queryClient = useQueryClient()
    return useMutation(
        (assignments: DTO.AddAssignment[]) => post<undefined, DTO.AddAssignment[]>('/scout-assignments', assignments),
        {
            onSettled: async () => {
                void queryClient.invalidateQueries(['board-players', boardParams])
                await queryClient.invalidateQueries(['assignment-widget'])
                await queryClient.invalidateQueries(['scout-assignments-calendar', params])
            },
        }
    )
}

export const useAddUnrequiredReport = ({
    incompleteReportParams,
    filterParams,
}: {
    incompleteReportParams: IncompleteReportsQueryParams
    filterParams: ScoutAssignmentQueryParams
}): UseMutationResult<JSONResponse, Error, DTO.UnrequiredReport> => {
    const queryClient = useQueryClient()
    return useMutation(
        (report: DTO.UnrequiredReport) =>
            post<undefined, DTO.UnrequiredReport>('/scout-assignments/unrequired', report),
        {
            onSettled: async () => {
                void queryClient.invalidateQueries(['scout-assignments-calendar', filterParams])
                void queryClient.invalidateQueries([
                    'incomplete-reports',
                    { scoutId: incompleteReportParams.scoutId, excludeUnrequried: true },
                ])
                await queryClient.invalidateQueries(['incomplete-reports', incompleteReportParams])
                await queryClient.invalidateQueries(['assignment-widget'])
            },
        }
    )
}

export type ScoutAssignmentQueryParams = {
    teamIds?: string[]
    gameDate?: string
    leagues?: Enum.League[]
    startDate?: string
    endDate?: string
    gameIds?: string[]
    scoutIds?: string[]
    regions?: Enum.Region[]
    boardId?: string
    assignmentIds?: string[]
    viewing?: Enum.AssignmentViewing
    level?: Enum.BoardLevels
    boardTier?: string | undefined
    includeDetailFields?: boolean
    stateProvinces?: string[]
}

type EditScoutsMutationType = { scouts: DTO.AssignedScout[]; boardId?: string }
export const useEditScouts = ({
    params,
}: {
    params?: ScoutAssignmentQueryParams
}): UseMutationResult<JSONResponse, Error, EditScoutsMutationType> => {
    const queryClient = useQueryClient()
    return useMutation(
        ({ scouts, boardId }: EditScoutsMutationType) =>
            put<undefined, DTO.AssignedScout[]>('/scout-assignments/status', scouts, { boardId }),
        {
            onMutate: async (variables) => {
                const assignmentQueryKey = ['scout-assignments-calendar', params]
                await queryClient.cancelQueries(assignmentQueryKey)
                const previousAssignmentData = queryClient.getQueryData(assignmentQueryKey) as DTO.AssignmentCalendar[]
                const updatedAssignmentData = previousAssignmentData.map((a) => {
                    if (
                        a.extendedProps.assignmentId === variables.scouts[0].assignmentId &&
                        a.extendedProps.scoutId === variables.scouts[0].entityId
                    ) {
                        return {
                            ...a,
                            extendedProps: {
                                ...a.extendedProps,
                                boardId: variables.boardId || a.extendedProps.boardId,
                                assignmentStatus: variables.scouts[0].assignmentStatus,
                            },
                        }
                    }
                    return a
                })
                queryClient.setQueryData(assignmentQueryKey, updatedAssignmentData)

                return { previousAssignmentData }
            },
            onError: (_err, _updatedAssignmentData, context) => {
                const { previousAssignmentData } = context as {
                    previousAssignmentData: DTO.AssignmentCalendar[]
                }
                queryClient.setQueryData(['scout-assignments', params], previousAssignmentData)
            },
            onSettled: async () => {
                void queryClient.invalidateQueries(['scout-assignments', params])
                void queryClient.invalidateQueries(['assignment-widget'])
                await queryClient.invalidateQueries(['scout-assignments-calendar'])
            },
        }
    )
}

export const useQueryScouts = (level?: Enum.BoardLevels): UseQueryResult<DTO.AssignedScout[]> =>
    useQuery<DTO.AssignedScout[]>(
        ['scouts', level],
        async () => (await get<DTO.AssignedScout[]>('/scout-assignments/scouts', { level })).data
    )

export const serverGetAssignmentById = async (
    assignmentId: string,
    session: Session | null
): Promise<DTO.AddAssignment> =>
    (await serverGet<DTO.AddAssignment>(`/scout-assignments/${assignmentId}`, session)).data

export const getAssignmentById = async (assignmentId: string): Promise<DTO.AddAssignment> =>
    (await get<DTO.AddAssignment>(`/scout-assignments/${assignmentId}`)).data

export const useQueryAssignmentById = (assignmentId: string | undefined): UseQueryResult<DTO.AddAssignment> =>
    useQuery(['scout-assignments', assignmentId], () => getAssignmentById(assignmentId as string), {
        enabled: !!assignmentId,
    })

const queryScoutAssignments = async (params?: ScoutAssignmentQueryParams): Promise<DTO.AssignmentCalendar[]> =>
    (await get<DTO.AssignmentCalendar[]>(`/scout-assignments/assignments`, params)).data

export const useQueryScoutAssignments = (
    params?: ScoutAssignmentQueryParams,
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery<DTO.AssignmentCalendar[]>(['scout-assignments', params], () => queryScoutAssignments(params), options)

const queryAssignmentWidget = async (): Promise<DTO.AssignmentWidget[]> =>
    (await get<DTO.AssignmentWidget[]>(`/scout-assignments/assignment-widget`)).data

export const useQueryAssignmentWidget = (
    options?: Omit<UseQueryOptions<DTO.AssignmentWidget[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentWidget[]> =>
    useQuery<DTO.AssignmentWidget[]>(['assignment-widget'], () => queryAssignmentWidget(), options)

export const useQueryScoutAssignmentsById = (
    params: { assignmentIds?: string[] },
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery<DTO.AssignmentCalendar[]>(
        ['scout-assignments', params],
        async () =>
            (
                await post<DTO.AssignmentCalendar[], { assignmentIds?: string[] }>(
                    `/scout-assignments/assignments_by_id`,
                    params
                )
            ).data,
        options
    )

const queryIncompleteAssignments = async (params?: {
    gameId?: string
    scoutId?: string
    assignmentId?: string
    startDate?: string
    endDate?: string
}): Promise<DTO.IncompleteReport[]> =>
    (await get<DTO.IncompleteReport[]>(`/scout-assignments/incomplete-reports`, params)).data

const queryIncompleteAssignmentsSummary = async (params?: {
    gameId?: string
    scoutId?: string
    assignmentId?: string
    startDate?: string
    endDate?: string
}): Promise<DTO.IncompleteReportSummary[]> =>
    (await get<DTO.IncompleteReportSummary[]>(`/scout-assignments/incomplete-reports-summary`, params)).data

export const serverGetIncompleteAssignmentsSummary = async (
    session: Session | null,
    params?: {
        gameId?: string
        scoutId?: string
        assignmentId?: string
        startDate?: string
        endDate?: string
    }
): Promise<DTO.IncompleteReportSummary[]> =>
    (await serverGet<DTO.IncompleteReportSummary[]>(`/scout-assignments/incomplete-reports-summary`, session, params))
        .data

export type IncompleteReportsQueryParams = Partial<{
    gameId: string
    scoutId: string
    assignmentId: string
    startDate: string
    endDate: string
    level: Enum.BoardLevels[]
}>
export const useQueryIncompleteReports = (
    params?: IncompleteReportsQueryParams,
    options?: Omit<UseQueryOptions<DTO.IncompleteReport[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IncompleteReport[]> =>
    useQuery<DTO.IncompleteReport[]>(['incomplete-reports', params], () => queryIncompleteAssignments(params), options)

export const useQueryIncompleteReportsSummary = (
    params?: IncompleteReportsQueryParams,
    options?: Omit<UseQueryOptions<DTO.IncompleteReportSummary[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.IncompleteReportSummary[]> =>
    useQuery<DTO.IncompleteReportSummary[]>(
        ['incomplete-reports-summary', params],
        () => queryIncompleteAssignmentsSummary(params),
        options
    )

export const useQueryMappedAssignmentsForCalendar = (
    params?: ScoutAssignmentQueryParams,
    options?: Omit<UseQueryOptions<DTO.AssignmentCalendar[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.AssignmentCalendar[]> =>
    useQuery<DTO.AssignmentCalendar[]>(
        ['scout-assignments-calendar', params],
        async () => (await get<DTO.AssignmentCalendar[]>(`/scout-assignments/calendar`, params)).data,
        options
    )
