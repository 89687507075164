import type { LegendColorProps } from './LegendColor'
import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Grid from '@mui/material/Grid'
import { useBreakPoints } from '../lib/hooks'
import LegendColor from './LegendColor'
import SecondaryPositionSelect from './SecondaryPositionSelect'

export type LegendProps = {
    title: string
    colors: LegendColorProps[]
    itemGap?: boolean
    endText?: string
    marginTop?: string
    centerLegend?: boolean
    legendWidth?: number
    isFooter?: boolean
    secondaryPosition?: string
    setSecondaryPosition?: (position: DTO.PlayerSecondaryPosition | undefined) => void
}

const LegendColors = ({
    colors,
    itemGap,
    secondaryPosition,
    legendWidth,
}: {
    colors: LegendColorProps[]
    itemGap: boolean
    secondaryPosition: string
    legendWidth?: number
}): JSX.Element => (
    <>
        {colors.map(({ label, color, fontColor, fontStyle, textDecoration }, index) => (
            <Grid item marginRight={itemGap ? 0.5 : 0} key={label + color} display="flex" alignItems="center">
                <Box
                    height={secondaryPosition ? '24px' : undefined}
                    width={legendWidth ? `${legendWidth / colors.length}px` : undefined}
                    minWidth={(legendWidth && index === 0) || index === colors.length - 1 ? '50px' : undefined}
                    textAlign="center"
                >
                    <LegendColor
                        label={label}
                        color={color}
                        fontColor={fontColor}
                        fontStyle={fontStyle}
                        textDecoration={textDecoration}
                        padding={legendWidth ? '0px' : undefined}
                    />
                </Box>
            </Grid>
        ))}
    </>
)

const Legend = ({
    title,
    colors,
    itemGap = false,
    endText,
    marginTop = '16px',
    centerLegend = false,
    legendWidth,
    isFooter = false,
    secondaryPosition = '',
    setSecondaryPosition = () => {},
}: LegendProps): JSX.Element => {
    const { isMobile, isTablet } = useBreakPoints()
    const abbr = isMobile || isTablet
    return (
        <Box
            sx={{
                marginTop,
            }}
            displayPrint={title === 'Percentile' && !isFooter ? 'none' : 'block'}
        >
            {!centerLegend && (
                <>
                    <Grid container columns={8}>
                        <Grid item marginRight={1} display="flex" justifyContent="center" alignItems="center">
                            <Typography
                                sx={{ '@media print': { fontSize: '18px' } }}
                                variant="caption"
                                fontWeight="medium"
                                align="center"
                            >
                                {title}
                                {secondaryPosition ? ' vs ' : ''}
                            </Typography>
                        </Grid>
                        {secondaryPosition && (
                            <Grid
                                item
                                marginRight={1}
                                padding={0}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box sx={{ '@media print': { display: 'none' } }}>
                                    <SecondaryPositionSelect
                                        secondaryPosition={secondaryPosition}
                                        setSecondaryPosition={setSecondaryPosition}
                                    />
                                </Box>
                                <Typography
                                    sx={{ '@media print': { fontSize: '18px', display: 'flex' } }}
                                    variant="caption"
                                    fontWeight="medium"
                                    align="center"
                                    display="none"
                                >
                                    {secondaryPosition}
                                </Typography>
                            </Grid>
                        )}
                        {!abbr && (
                            <LegendColors
                                colors={colors}
                                itemGap={itemGap}
                                secondaryPosition={secondaryPosition}
                                legendWidth={legendWidth}
                            />
                        )}
                    </Grid>
                    {abbr && (
                        <Grid container sx={{ marginTop: 1 }}>
                            <LegendColors colors={colors} itemGap={itemGap} secondaryPosition={secondaryPosition} />
                        </Grid>
                    )}
                </>
            )}
            {centerLegend && legendWidth && (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: endText ? 'space-between' : 'center' }}>
                        <Typography variant="subtitle2">{title}</Typography>
                        {endText && <Typography variant="subtitle2">{endText}</Typography>}
                    </Box>
                    <Box sx={{ width: `${legendWidth}px`, display: 'flex', justifyContent: 'center' }}>
                        {colors.map(({ label, color, fontColor }) => (
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    width: `${legendWidth / colors.length}px`,
                                }}
                                key={label + color}
                            >
                                <LegendColor padding="0px" label={label} color={color} fontColor={fontColor} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default Legend
