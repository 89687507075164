import type {
    UseInfiniteQueryResult,
    UseInfiniteQueryOptions,
    UseMutationResult,
    InfiniteData,
    QueryKey,
} from '@tanstack/react-query'
import type { PaginatedPostsResp } from './usePosts'
import type { JSONResponse } from '../api'
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, put, post } from '../api'

export type PaginatedNotificationsResp = PaginatedPostsResp<DTO.Notification> & {
    unseenCount: number
}

type InfiniteQueryPaginatedNotificationsResp = InfiniteData<PaginatedNotificationsResp>

const queryAllUserNotifications = async (
    unread: boolean,
    pageParam = 1,
    perPage = 20
): Promise<PaginatedNotificationsResp> =>
    (await get<PaginatedNotificationsResp>('/notifications', { unread, page: pageParam, perPage })).data

export const useQueryAllUserNotifications = (
    unread: boolean,
    perPage?: number,
    options?: Pick<UseInfiniteQueryOptions<PaginatedNotificationsResp>, 'enabled' | 'refetchInterval'>
): UseInfiniteQueryResult<PaginatedNotificationsResp> =>
    useInfiniteQuery(
        ['notifications', unread, perPage],
        ({ pageParam = 1 }) => queryAllUserNotifications(unread, pageParam as number, perPage),
        {
            ...options,
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            getNextPageParam: (lastPage) => (lastPage?.info?.hasNextPage ? lastPage.info.nextPage : false),
            refetchInterval: 30000,
        }
    )

export const useMarkNotificationAsRead = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation(
        (notificationRecipientId: string) =>
            put<undefined, Record<string, never>>(`/notification-recipients/${notificationRecipientId}/read`, {}),
        {
            onMutate: async (notificationRecipientId: string) => {
                await queryClient.cancelQueries(['notifications'])

                const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedNotificationsResp>([
                    'notifications',
                ])
                if (!queriesData.length) return {}
                const [[queryKey, oldData]] = queriesData

                const newPages = oldData?.pages.map((page) => ({
                    ...page,
                    results: page.results.map((oldNotification) => {
                        if (oldNotification.recipient.id === notificationRecipientId) {
                            return {
                                ...oldNotification,
                                readAt: new Date().toJSON(),
                            }
                        }
                        return oldNotification
                    }),
                }))
                queryClient.setQueryData(queryKey, {
                    ...oldData,
                    pages: newPages,
                })

                // Return a context object with the snapshotted value
                return { queryKey, oldData }
            },
            onError: (error, data, context) => {
                if (context) {
                    const { queryKey, oldData } = context as {
                        queryKey?: QueryKey
                        oldData?: InfiniteQueryPaginatedNotificationsResp
                    }
                    if (queryKey && oldData) queryClient.setQueryData(queryKey, oldData)
                }
            },
            onSettled: () => queryClient.invalidateQueries(['notifications']),
        }
    )
}

export const useMarkNotificationAsUnread = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation(
        (notificationRecipientId: string) =>
            put<undefined, Record<string, never>>(`/notification-recipients/${notificationRecipientId}/unread`, {}),
        {
            onMutate: async (notificationRecipientId: string) => {
                await queryClient.cancelQueries(['notifications'])

                const queriesData = queryClient.getQueriesData<InfiniteQueryPaginatedNotificationsResp>([
                    'notifications',
                ])
                if (!queriesData.length) return {}
                const [[queryKey, oldData]] = queriesData

                const newPages = oldData?.pages.map((page) => ({
                    ...page,
                    results: page.results.map((oldNotification) => {
                        if (oldNotification.recipient.id === notificationRecipientId) {
                            return {
                                ...oldNotification,
                                readAt: null,
                            }
                        }
                        return oldNotification
                    }),
                }))
                queryClient.setQueryData(queryKey, {
                    ...oldData,
                    pages: newPages,
                })

                // Return a context object with the snapshotted value
                return { queryKey, oldData }
            },
            onError: (error, data, context) => {
                if (context) {
                    const { queryKey, oldData } = context as {
                        queryKey?: QueryKey
                        oldData?: InfiniteQueryPaginatedNotificationsResp
                    }
                    if (queryKey && oldData) queryClient.setQueryData(queryKey, oldData)
                }
            },
            onSettled: () => queryClient.invalidateQueries(['notifications']),
        }
    )
}

export const useMarkAllNotificationsAsRead = (): UseMutationResult<JSONResponse, Error, Record<string, never>> => {
    const queryClient = useQueryClient()
    return useMutation(() => post('/notification-recipients/read-all', {}), {
        onSuccess: () => queryClient.invalidateQueries(['notifications']),
    })
}

export const useMarkAllNotificationsAsSeen = (): UseMutationResult<JSONResponse, Error, Record<string, never>> => {
    const queryClient = useQueryClient()
    return useMutation(() => post('/notification-recipients/seen-all', {}), {
        onSuccess: () => queryClient.invalidateQueries(['notifications']),
    })
}

export const useMarkAllPostNotificationsAsRead = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation((postId: string) => post(`/notification-recipients/read-all/${postId}`, {}), {
        onSuccess: () => queryClient.invalidateQueries(['notifications']),
    })
}
