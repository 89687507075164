import type { Constants } from '../definitions/dto/Constant'

type ConstantsConstants = {
    [T in keyof Constants]: {
        description: string
        type: Constants[T] extends number ? 'integer' : Constants[T] extends boolean ? 'boolean' : 'string'
        value: Constants[T]
    }
}

const constants: ConstantsConstants = {
    isNBAOffseason: {
        description: 'Toggle representing if NBA season is in progress or in offseason state',
        type: 'boolean',
        value: false,
    },
    isGLeagueOffseason: {
        description: 'Toggle representing if G League season is in progress or in offseason state',
        type: 'boolean',
        value: false,
    },
    isNCAAOffseason: {
        description: 'Toggle representing if NCAA season is in progress or in offseason state',
        type: 'boolean',
        value: false,
    },
    statsYear: { description: 'Current season stats are queried by', type: 'integer', value: 2024 },
    salaryYear: { description: 'Current season salaries and rosters are queried by', type: 'integer', value: 2024 },
    profileYear: {
        description: 'Season player profile stats and models use for current aggregate data',
        type: 'integer',
        value: 2024,
    },
    scheduleYear: {
        description: 'Current season schedule and standings data is queried by',
        type: 'integer',
        value: 2024,
    },
    ncaaSeason: { description: 'Current season NCAA data is queried by', type: 'integer', value: 2024 },
    draftYear: { description: 'Current year of NBA draft cycle', type: 'integer', value: 2025 },
    glgDraftYear: { description: 'Current year of G-League draft cycle', type: 'integer', value: 2025 },
    amateurShowIntensityGradeDate: {
        description: 'Date to start showing intensity grades in the amateur header',
        type: 'string',
        value: '2025-05-30',
    },
    amateurShowDraftRangeDate: {
        description: 'Date to start showing draft range in the amateur header',
        type: 'string',
        value: '2023-11-07',
    },
    defaultPrimaryAmatuerBoardType: {
        description: 'Default type of a primary Amatuer board',
        type: 'string',
        value: 'REGION',
    },
    defaultPrimaryProBoardType: {
        description: 'Default type of a primary Pro board',
        type: 'string',
        value: 'TRADE',
    },
    ISTGroup: { description: 'Knicks IST Group', type: 'string', value: 'East Group A' },
    gLeagueRegularSeasonStartDate: {
        description: 'Date of G-League regular season tip off (post-showcase)',
        type: 'string',
        value: '2024-12-27',
    },
    salaryGuaranteeDate: {
        description: 'Date of salary guarantee for the current season',
        type: 'string',
        value: '2025-01-10',
    },
    nbaPostseasonStartDate: {
        description: 'Date to determine DTD injury status for the current season',
        type: 'string',
        value: '2025-04-19',
    },
}

export default Object.entries(constants).map(([key, { value, ...rest }]) => ({ key, value: value.toString(), ...rest }))
