import type { SystemCssProperties } from '@mui/system'
import type { ScaleLinear } from 'd3'
import Rainbow from 'rainbowvis.js'
import { green, red } from '@mui/material/colors'
import { scaleLinear } from 'd3'
import { minutesRequirementsPro, minutesRequirementsAm } from '../../shared/constants/stats'
import { asNumber, isNumber } from './math'

interface RainbowVisOpts {
    rangeMin?: number
    rangeMax?: number
    spectrum?: string[]
}
export const getColorOnGradient = (
    rangeNum: number,
    maxColor: string,
    minColor = 'white',
    opts?: RainbowVisOpts
): string => {
    const { rangeMin = 0, rangeMax = 100, spectrum } = opts || {}

    const rainbow = new Rainbow()
    rainbow.setNumberRange(rangeMin, rangeMax)
    if (spectrum && spectrum.length > 1) rainbow.setSpectrum(...spectrum)
    else rainbow.setSpectrum(minColor, maxColor)

    return `#${rainbow.colourAt(rangeNum)}`
}

export const getCalloutPercentColor = (percent: number): string => getColorOnGradient(percent, '#ffab91')

export interface CellColor {
    backgroundColor: string
    color?: string
}
export const percentileColors: Record<number, CellColor> = {
    90: { backgroundColor: green[300] },
    80: { backgroundColor: green[200] },
    70: { backgroundColor: green[100] },
    60: { backgroundColor: green[50] },
    10: { backgroundColor: red[300] },
    20: { backgroundColor: red[200] },
    30: { backgroundColor: red[100] },
    40: { backgroundColor: red[50] },
} as const

export const getLeagueAverageColor = (percent: number): CellColor | undefined => {
    if (percent >= 90) return percentileColors[90]
    if (percent >= 80) return percentileColors[80]
    if (percent >= 70) return percentileColors[70]
    if (percent >= 60) return percentileColors[60]

    if (percent <= 10) return percentileColors[10]
    if (percent <= 20) return percentileColors[20]
    if (percent <= 30) return percentileColors[30]
    if (percent <= 40) return percentileColors[40]

    return undefined
}

export const getCalloutColor = (
    playerVal: unknown,
    percentiles: DTO.PercentileMap,
    league: Enum.League,
    season?: number | null,
    sortAsc = true
): CellColor | undefined => {
    let thresholds = season ? percentiles[`${league}-${season}`] : percentiles[league]
    let value = asNumber(playerVal)

    if (thresholds && value) {
        if (!sortAsc) {
            value = -value
            thresholds = [...thresholds].map((t) => t && -t).reverse() as unknown as DTO.Percentiles
        }
        if (thresholds[8] && value >= thresholds[8]) return percentileColors[90]
        if (thresholds[7] && value >= thresholds[7]) return percentileColors[80]
        if (thresholds[6] && value >= thresholds[6]) return percentileColors[70]

        if (thresholds[0] && value <= thresholds[0]) return percentileColors[10]
        if (thresholds[1] && value <= thresholds[1]) return percentileColors[20]
        if (thresholds[2] && value <= thresholds[2]) return percentileColors[30]
    }
    return undefined
}

export const getThresholdColor = (
    playerVal: unknown,
    percentiles: DTO.PercentileMap,
    league: Enum.League,
    playerLevel: Enum.PlayerLevel | null,
    playerMinsTotal: number | null | undefined,
    season?: number | null,
    sortAsc = true
): CellColor | undefined => {
    if ((playerLevel === 'PRO' && playerMinsTotal && playerMinsTotal < minutesRequirementsPro) || !playerMinsTotal)
        return undefined
    if ((playerLevel === 'AM' && playerMinsTotal && playerMinsTotal < minutesRequirementsAm) || !playerMinsTotal)
        return undefined

    let thresholds = season ? percentiles[`${league}-${season}`] : percentiles[league]
    let value = asNumber(playerVal)

    if (thresholds && isNumber(value)) {
        if (!sortAsc) {
            value = -value
            thresholds = [...thresholds].map((t) => t && -t).reverse() as unknown as DTO.Percentiles
        }
        if (thresholds[8] && value >= thresholds[8]) return percentileColors[90]
        if (thresholds[7] && value >= thresholds[7]) return percentileColors[80]
        if (thresholds[6] && value >= thresholds[6]) return percentileColors[70]
        if (thresholds[5] && value >= thresholds[5]) return percentileColors[60]

        if (thresholds[0] && value <= thresholds[0]) return percentileColors[10]
        if (thresholds[1] && value <= thresholds[1]) return percentileColors[20]
        if (thresholds[2] && value <= thresholds[2]) return percentileColors[30]
        if (thresholds[3] && value <= thresholds[3]) return percentileColors[40]
    }
    return undefined
}

export const getMeasurementThresholdColor = (
    measurement: DTO.Measurements | 'height',
    playerVal: number | null,
    league: Enum.League,
    secondaryPosition: DTO.PlayerSecondaryPosition,
    percentiles?: DTO.MeasurementPercentileMap
): CellColor | undefined => {
    if (!percentiles) return undefined

    const measurementType: DTO.Measurements = measurement === 'height' ? 'heightBarefoot' : measurement
    const key: DTO.MeasurementPercentileKey = `${measurementType}-${secondaryPosition}-${league}`
    const thresholds = percentiles[key]
    if (thresholds && isNumber(playerVal)) {
        const descending = measurement === 'run34' || measurement === 'laneAgility' || measurement === 'bodyFat'

        if (isNumber(thresholds['90']) && playerVal >= thresholds['90'])
            return descending ? percentileColors[10] : percentileColors[90]
        if (isNumber(thresholds['80']) && playerVal >= thresholds['80'])
            return descending ? percentileColors[20] : percentileColors[80]
        if (isNumber(thresholds['70']) && playerVal >= thresholds['70'])
            return descending ? percentileColors[30] : percentileColors[70]
        if (isNumber(thresholds['60']) && playerVal >= thresholds['60'])
            return descending ? percentileColors[40] : percentileColors[60]
        if (isNumber(thresholds['40']) && playerVal <= thresholds['40'])
            return descending ? percentileColors[60] : percentileColors[40]
        if (isNumber(thresholds['30']) && playerVal <= thresholds['30'])
            return descending ? percentileColors[70] : percentileColors[30]
        if (isNumber(thresholds['20']) && playerVal <= thresholds['20'])
            return descending ? percentileColors[80] : percentileColors[20]
        if (isNumber(thresholds['10']) && playerVal <= thresholds['10'])
            return descending ? percentileColors[90] : percentileColors[10]
    }
    return undefined
}

export const getLeagueAveragePlusMinusColor = (percentFromAvg: number): CellColor | undefined =>
    // scale +/- to 0-100 scale based on +/- 10 being the equivalent of 1-100
    getLeagueAverageColor(50 + percentFromAvg * 5)

export const seedingProbColorScale = scaleLinear<string>()
    .domain([0, 0.4, 1])
    .range(['#ffffff', '#e7f6c7', '#34a4a2'])
    .clamp(true)

export const playoffProbColorScale = scaleLinear<string>().domain([0, 1.5]).range(['#ffffff', '#39ad39']).clamp(true)

export const matchupProbColorScale = scaleLinear<string>()
    .domain([0, 0.3, 1])
    .range(['#ffffff', '#39ad39', '#39ad39'])
    .clamp(true)

export const contractGradeColorScale = scaleLinear<string>()
    .domain([0, 2.5, 5])
    .range(['#f44336', '#ffffff', '#388e3c'])
    .clamp(true)

export const skillColorScale = scaleLinear<string>()
    .domain([0, 50, 100])
    .range(['#f44336', '#ffffff', '#388e3c'])
    .clamp(true)

export const locColorScale = scaleLinear<string>()
    .domain([0, 4, 8])
    .range(['#f44336', '#ffffff', '#388e3c'])
    .clamp(true)

export const strategyLocColorScale = {
    0: 'rgb(160, 80, 80)',
    1: 'rgb(226, 137, 136)',
    2: 'rgb(251, 231, 163)',
    3: 'rgb(253, 243, 208)',
    4: 'rgb(228, 239, 220)',
    5: 'rgb(203, 223, 184)',
    6: 'rgb(177, 207, 149)',
    7: 'rgb(94, 129, 63)',
    8: 'rgb(55, 86, 35)',
}

export const likelihoodScale = scaleLinear<string>()
    .domain([0, 0.5, 1])
    .range(['#f44336', '#ffffff', '#388e3c'])
    .clamp(true)
export const teamStatsColorScale = ({ value }: { value: number }): { color: string; backgroundColor: string } => {
    const colors = ['#388e3c', '#ffffff', '#f44336']
    const domain: number[] = [0, 15, 30]
    const color = value <= 3 || value >= 28 ? 'common.white' : 'text.primary'

    const colorScale = scaleLinear<string>().domain(domain).range(colors).clamp(true)
    return { color, backgroundColor: colorScale(value) }
}
export const teamStatsGreyScale = ({ value }: { value: number }): { color: string; backgroundColor: string } => {
    const colors = ['#f5f5f5', '#bdbdbd', '#757575']
    const domain: number[] = [0, 15, 30]
    const color = value >= 25 ? 'common.white' : 'text.primary'

    const colorScale = scaleLinear<string>().domain(domain).range(colors).clamp(true)
    return { color, backgroundColor: colorScale(value) }
}
export const getPredictiveRatingsColorScale = (value: number, min: number, max: number): string => {
    if (!value) return 'white'
    const colorScale = scaleLinear<string>().domain([min, 0, max]).range(['#ed0a04', '#fbf6d6', '#69d463']).clamp(true)
    return colorScale(value)
}

export const getCurrentSkillsColorScale = (avg: number, value: number): string => {
    if (!avg) return '#ffffff'
    const colorScale = scaleLinear<string>().domain([0, avg, 100]).range(['#f44336', '#ffffff', '#388e3c'])
    return colorScale(value)
}

export const surplusColorScale = scaleLinear<string>()
    .domain([-40000000, 0, 40000000])
    .range(['#f44336', '#ffffff', '#388e3c'])
    .clamp(true)

export const salaryColorScale = scaleLinear<string>()
    .domain([0, 10000000, 80000000])
    .range(['#ffffff', '#e7f6c7', '#34a4a2'])
    .clamp(true)

export const statColorScale = (val: number, min: number, max: number, mid: number): string => {
    const scale = scaleLinear<string>().domain([min, mid, max]).range(['#f44336', '#ffffff', '#388e3c']).clamp(true)
    return scale(val)
}

export const getConveyanceColor = (
    type: Enum.PickConveyance,
    {
        isProjectedToConvey,
        hasAssetGroupConveyed,
    }: Pick<DTO.ConveyanceSeason, 'isProjectedToConvey' | 'hasAssetGroupConveyed'>
): SystemCssProperties | undefined => {
    if (type === 'FORFEITED') return percentileColors[20]
    if (isProjectedToConvey) return percentileColors[80]
    if (hasAssetGroupConveyed) return { color: 'text.disabled' }
    if (isProjectedToConvey === false) return percentileColors[20]
    return undefined
}

export const tradeablePicksColorScale = (maxPicks: number): ScaleLinear<string, string, never> =>
    scaleLinear<string>()
        .domain([0, maxPicks / 2, maxPicks])
        .range(['#ffffff', green[200]])
        .clamp(true)

export const getTeamStyles = ({
    teamId,
    teamPrimaryColor,
}: Partial<Pick<DTO.DepthChartTeam, 'teamId' | 'teamPrimaryColor'>>): SystemCssProperties => ({
    backgroundColor: teamPrimaryColor || 'gray',
    // san antonio text color
    color: teamId === '42E68405-31AD-4524-8C16-502FA0261A85' ? 'black' : 'white',
})

export const hexToRgb = (hex: string, alpha: number): string | null => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    const newHex = hex.replace(shorthandRegex, (_m, r: string, g: string, b: string) => `${r}${r}${g}${g}${b}${b}`)

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)
    return result
        ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},${alpha})`
        : null
}
