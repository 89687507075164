import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export const useSkillsStats = (playerId: string | undefined): UseQueryResult<DTO.SkillsStats[]> =>
    useQuery(
        ['skills-stats', playerId],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.SkillsStats[]>(`/player-skills/${playerId}/skills-stats`)).data,
        { enabled: !!playerId }
    )

export const useCareerSkills = (playerId: string | undefined): UseQueryResult<DTO.CareerSkills[]> =>
    useQuery(
        ['career-stats', playerId],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.CareerSkills[]>(`/player-skills/${playerId}/career-skills`)).data,
        { enabled: !!playerId }
    )

export const useRawSkills = (
    playerId: string | undefined,
    asOfDate?: string,
    options?: Omit<UseQueryOptions<DTO.SkillsByScout>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.SkillsByScout> =>
    useQuery<DTO.SkillsByScout>(
        ['skills-by-scout', playerId, asOfDate],
        async () =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            (await get<DTO.SkillsByScout>(`/player-skills/${playerId}/skills-by-scout`, { asOfDate })).data,
        { enabled: !!playerId, ...options }
    )

export const getCurrentSkills = async (
    playerId: string,
    secondaryPosition: DTO.PlayerSecondaryPosition
): Promise<DTO.CurrentScoutingSkills> =>
    (await get<DTO.CurrentScoutingSkills>(`/player-skills/${playerId}/${secondaryPosition}/current`)).data

export const useGetCurrentSkills = ({
    playerId,
    secondaryPosition,
}: {
    playerId: string | undefined
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined
}): UseQueryResult<DTO.CurrentScoutingSkills> =>
    useQuery<DTO.CurrentScoutingSkills>(
        ['player-skills', playerId, secondaryPosition, 'current'],
        () =>
            // @ts-expect-error playerId and secondaryPosition will only get referenced here when it is defined (enabled)
            getCurrentSkills(playerId, secondaryPosition),
        { enabled: !!secondaryPosition && !!playerId }
    )
