import type { SearchInputProps, SearchResultType } from './SearchAutocomplete'
import React, { useCallback } from 'react'
import ErrorMessage from '../form/ErrorMessage'
import SearchAutocomplete from './SearchAutocomplete'
import { mapNewAutocompleteEntryToSearchResult } from '@/lib/utils/search'

type MultipleSelectSearchProps<N extends boolean> = Pick<
    SearchInputProps<true, false>,
    'placeholder' | 'error' | 'errorText' | 'required' | 'searchType'
> & {
    value: SearchResultType<N>[] | undefined
    onChange: (value: SearchResultType<N>[]) => void
    autoFocus?: boolean
    disabledSlugs?: string[]
    allowEntityCreation: N
}

function MultipleSelectSearch<AllowCreation extends boolean = false>({
    value,
    onChange: handleChange,
    placeholder,
    error,
    errorText,
    required,
    searchType,
    autoFocus,
    disabledSlugs,
    allowEntityCreation,
}: MultipleSelectSearchProps<AllowCreation>): JSX.Element {
    const onChange = useCallback<SearchInputProps<true, AllowCreation>['onChange']>(
        (_e, options, reason) => {
            if (reason === 'blur') return
            const vals = options.map(mapNewAutocompleteEntryToSearchResult) as (
                | SearchResultType<AllowCreation>
                | undefined
            )[]
            const validMultiSelectOptions = vals.filter((o) => typeof o !== 'string') as DTO.SearchResult[]
            handleChange(validMultiSelectOptions)
        },
        [handleChange]
    )
    return (
        <>
            <SearchAutocomplete<true, AllowCreation>
                autoFocus={autoFocus}
                multiple
                value={value || []}
                placeholder={placeholder}
                error={error}
                errorText={errorText}
                required={required}
                onChange={onChange}
                searchType={searchType}
                fullWidth
                blurOnSelect={!allowEntityCreation}
                disabledSlugs={disabledSlugs}
            />
            {error && errorText && <ErrorMessage text={errorText} />}
        </>
    )
}

export default MultipleSelectSearch
