import type TableConfig from '../../lib/types/tableConfigTypes'
import type { MouseEvent } from 'react'
import type { TableConfigField } from '../../lib/types/tableConfigTypes'
import type { TableOrder } from './sorting'
import React from 'react'
import Box from '@mui/material/Box'
import MuiTableCell from '@mui/material/TableCell'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Badge from '@mui/material/Badge'
import { useSearchParams } from 'next/navigation'
import { defaultTableRowCss } from './styles'

export type TableHeadProps<T, T2> = {
    fields: TableConfig<T, T2>['fields']
    onSort: (event: MouseEvent<unknown>, property: Array<keyof T>) => void
    tableOrder: TableOrder<T>[]
    isLegendVisible: string
    setIsLegendVisible: (value: string) => void
    sortState: TableConfig<T, T2>['sortState']
    setSortState: TableConfig<T, T2>['setSortState']
    stickyHeader?: boolean
    hideSorting?: boolean
    hideHeaders?: boolean
    hideSortIcon?: boolean
}

const TableHead = <T, T2>(props: TableHeadProps<T, T2>): JSX.Element => {
    const searchParams = useSearchParams()
    const printOnly = !!searchParams?.get('printOnly')
    const {
        fields,
        tableOrder,
        onSort,
        isLegendVisible,
        setIsLegendVisible,
        stickyHeader,
        hideSorting,
        sortState,
        setSortState,
        hideHeaders,
        hideSortIcon,
    } = props
    const createSortHandler = (property: Array<keyof T>) => (event: MouseEvent<unknown>) => {
        onSort(event, property)
    }

    const customSort = !!(sortState && setSortState)
    const headers = fields.some(({ header }) => header)
        ? fields.reduce(
              (
                  acc: {
                      title: string | null
                      colSpan: number
                      key: number
                      superHeadCellFormat: TableConfigField<T>['superHeadCellFormat']
                  }[],
                  { header, superHeadCellFormat }
              ) => {
                  const title = header || null
                  if (!acc.length) acc.push({ title, colSpan: 1, key: 0, superHeadCellFormat })
                  else {
                      const prev = acc[acc.length - 1]
                      if (prev.title === title) prev.colSpan += 1
                      else acc.push({ title, colSpan: 1, key: prev.key + 1, superHeadCellFormat })
                  }
                  return acc
              },
              []
          )
        : null

    return (
        <MuiTableHead
            sx={({ spacing }) => ({
                '.MuiTableCell-head': {
                    '@media print': { padding: spacing(0.5), fontSize: '14px' },
                },
                visibility: hideHeaders ? 'collapse' : undefined,
            })}
        >
            {!!headers?.length && (
                <MuiTableRow className="super">
                    {headers.map(({ key, title, colSpan, superHeadCellFormat }) => (
                        <MuiTableCell
                            key={key}
                            colSpan={colSpan}
                            sx={{
                                textAlign: 'center',
                                borderBottom: !title ? 'none' : undefined,
                                backgroundColor: 'common.white',
                                ...(typeof superHeadCellFormat === 'function'
                                    ? superHeadCellFormat(props)
                                    : superHeadCellFormat),
                            }}
                        >
                            {title}
                        </MuiTableCell>
                    ))}
                </MuiTableRow>
            )}
            <MuiTableRow sx={defaultTableRowCss}>
                {fields.map((field, idx, self) => {
                    const sortColumn = hideSorting
                        ? undefined
                        : field.sortColumn && (Array.isArray(field.sortColumn) ? field.sortColumn : [field.sortColumn])
                    const isColumnSorted = sortColumn ? tableOrder.map((d) => d.orderBy).includes(sortColumn[0]) : false
                    const orderDirection = tableOrder.find((d) => d.orderBy === sortColumn?.[0])?.order
                    const tableArrayIndex =
                        sortColumn && tableOrder.length > 1
                            ? tableOrder.map((d) => d.orderBy).indexOf(sortColumn[0]) + 1
                            : 0
                    return (
                        <Tooltip
                            key={`${field.tooltip || ''}-${field.key}`}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -15],
                                        },
                                    },
                                ],
                            }}
                            title={field.tooltip || ''}
                        >
                            <MuiTableCell
                                key={field.key}
                                align={field.numeric ? 'right' : 'left'}
                                sortDirection={isColumnSorted ? orderDirection : false}
                                sx={{
                                    borderRight: field.borderRight ? '1px solid' : undefined,
                                    borderRightColor: 'divider',
                                    borderLeft:
                                        idx > 0 &&
                                        field.header &&
                                        self[idx - 1].header &&
                                        field.header !== self[idx - 1].header
                                            ? '1px solid'
                                            : undefined,
                                    borderLeftColor: 'divider',
                                    '.MuiTableSortLabel-icon': { displayPrint: 'none' },
                                    zIndex: stickyHeader ? 4 : undefined,
                                    top: stickyHeader && !!headers?.length ? 37 : undefined,
                                    backgroundColor: 'common.white',
                                    whiteSpace: field.icon ? 'nowrap' : undefined,
                                    ...(typeof field.headCellFormat === 'function'
                                        ? field.headCellFormat(props)
                                        : field.headCellFormat),
                                }}
                            >
                                <Box
                                    sx={{ display: 'flex', justifyContent: field.numeric ? 'flex-end' : 'flex-start' }}
                                >
                                    {sortColumn && !customSort ? (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: field.numeric ? 'flex-end' : 'flex-start',
                                                alignItems: 'center',
                                                gap: '2px',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: 'text.secondary',
                                                    '> .arrow': { opacity: '1 !important' },
                                                },
                                            }}
                                            onClick={createSortHandler(sortColumn)}
                                            component="span"
                                        >
                                            {!field.numeric && (
                                                <Box
                                                    sx={{
                                                        color:
                                                            isColumnSorted && hideSortIcon && !printOnly
                                                                ? '#1976d2'
                                                                : undefined,
                                                    }}
                                                >
                                                    {field.label}
                                                </Box>
                                            )}
                                            {!hideSortIcon && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        opacity: isColumnSorted ? 1 : 0,
                                                        transitionDuration: '.2s',
                                                    }}
                                                    className="arrow"
                                                >
                                                    <Badge
                                                        color="info"
                                                        badgeContent={tableArrayIndex}
                                                        overlap="rectangular"
                                                        sx={{
                                                            '.MuiBadge-badge': {
                                                                backgroundColor: 'rgba(0,0,0,0)',
                                                                color: 'text.secondary',
                                                                zIndex: 1,
                                                                minWidth: '15px',
                                                                maxWidth: '15px',
                                                                maxHeight: '15px',
                                                                minHeight: '15px',
                                                                fontSize: '10px',
                                                                right: 15,
                                                            },
                                                            displayPrint: 'none !important',
                                                        }}
                                                    >
                                                        {orderDirection === 'desc' ? (
                                                            <ArrowDownwardIcon
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    color: 'text.secondary',
                                                                    zIndex: 2,
                                                                }}
                                                            />
                                                        ) : (
                                                            <ArrowUpwardIcon
                                                                sx={{
                                                                    fontSize: '16px',
                                                                    color: 'text.secondary',
                                                                    zIndex: 2,
                                                                }}
                                                            />
                                                        )}
                                                    </Badge>
                                                </Box>
                                            )}
                                            {field.numeric && (
                                                <Box
                                                    sx={{
                                                        color:
                                                            isColumnSorted && hideSortIcon && !printOnly
                                                                ? '#1976d2'
                                                                : undefined,
                                                    }}
                                                >
                                                    {field.label}
                                                </Box>
                                            )}
                                        </Box>
                                    ) : (
                                        field.label
                                    )}
                                    <Box displayPrint="none" component="span">
                                        {field.icon?.(field.key.toString(), setIsLegendVisible)}
                                    </Box>
                                </Box>
                                {isLegendVisible === field.key && field.legend && field.legend(setIsLegendVisible)}
                            </MuiTableCell>
                        </Tooltip>
                    )
                })}
            </MuiTableRow>
        </MuiTableHead>
    )
}

export default TableHead
